import React from "react"
// import MainLayout from "src/layouts/MainLayout"
import MainLayout from "src/modules/adminpanel/MyLayout/Types/architect"
import { ConfigPage, MyPageLayoutHelpers } from "@macashipo/mlib"

MyPageLayoutHelpers.setDefaultMyPageLayout((props: any) => {
  return <MainLayout>{props.children}</MainLayout>
})
MyPageLayoutHelpers.addTypes("blank", (props: any) => {
  return <div>{props.children}</div>
})
// function PageGeneric(props) {
//   const configPage = ConfigPage.createFromConfigPageServer(props.configPage)
//   const CMyPage = configPage.getMyPage(props)
//   console.warn("props", props)
//   const CLayout = configPage.getWrapMyPageLayout({
//     ...props,
//     children: CMyPage,
//   })
//   return CLayout
// }

interface IProps {
  configPage?: any
  isRootLevel?: any
}
class PageGenericClass extends React.Component<IProps> {
  // constructor(props) {
  //   super(props)
  //   console.warn("constructor page generic")
  // }
  render() {
    // console.warn("render page generic", this.props.configPage)
    return (
      <MainLayout>
        <PageGenericChild key={new Date().getTime()} {...this.props} />
      </MainLayout>
    )
  }
}
function PageGenericChild(props) {
  const configPage = ConfigPage.createFromConfigPageServer(props.configPage)
  //for test force show page type table
  // configPage.getType = () => {
  //   return "table"
  // }
  //end test
  const CMyPage = configPage.getMyPage(props)
  // console.warn("render page generic child", props.configPage)
  return CMyPage
}
export default PageGenericClass
