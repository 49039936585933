import React from "react"
import { MyTableCellHelpers } from "@macashipo/mlib"

const List = {}
const MyTableCellOne = {
  init() {
    MyTableCellHelpers.addTypesFromListLazy(List)
  },
}

export default MyTableCellOne
