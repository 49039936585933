import { MyFormControlHelpers, MyLayoutHelpers } from "@macashipo/mlib"
import React from "react"

const MFCText = React.lazy(() => import("./Types/text"))
const MFCAlert = React.lazy(() => import("./Types/alert"))
const MFCTextarea = React.lazy(() => import("./Types/textarea"))
const MFCSelect2 = React.lazy(() => import("./Types/select2"))
const MFCDatePicker = React.lazy(() => import("./Types/datepicker"))
const MFCNumberDecimal = React.lazy(() => import("./Types/number_decimal"))
const MFCNumberNatural = React.lazy(() => import("./Types/number_natural"))
const MFCNumber = React.lazy(() => import("./Types/number"))
const MFCCurrency = React.lazy(() => import("./Types/currency"))
const MFCPassword = React.lazy(() => import("./Types/password"))
const MFC_checkbox = React.lazy(() => import("./Types/checkbox"))
const MFC_readonly = React.lazy(() => import("./Types/readonly"))
const MFC_layout_row = React.lazy(() => import("./Types/layout_row"))
const MFC_layout_grid = React.lazy(() => import("./Types/layout_grid"))
const MFC_layout_group = React.lazy(() => import("./Types/layout_group"))
const MFC_timepicker = React.lazy(() => import("./Types/timepicker"))
const MFC_timepicker_text = React.lazy(() => import("./Types/timepicker_text"))
const MFC_datepicker_text = React.lazy(() => import("./Types/datepicker_text"))
const MFC_arrtext = React.lazy(() => import("./Types/arrtext"))
const MFC_select_tag = React.lazy(() => import("./Types/select_tag"))
const MFC_datetimepicker = React.lazy(() => import("./Types/datetimepicker"))
const MFC_select_table_api = React.lazy(() =>
  import("./Types/select_table_api")
)
const MFC_custom = React.lazy(() => import("./Types/custom"))
const MFC_checkbox_yesno = React.lazy(() => import("./Types/checkbox_yesno"))
const MFC_checkbox_inbox = React.lazy(() => import("./Types/checkbox_inbox"))
const MFC_percent = React.lazy(() => import("./Types/percent"))
const MFC_range = React.lazy(() => import("./Types/range"))
const MFC_files = React.lazy(() => import("./Types/files"))
const MFC_textareafull = React.lazy(() => import("./Types/textareafull"))
const MFC_readonly_html = React.lazy(() => import("./Types/readonly_html"))
const MFC_html_editor = React.lazy(() => import("./Types/html_editor"))
const MFC_selectlist = React.lazy(() => import("./Types/selectlist"))
const MFC_selectlist_check = React.lazy(() =>
  import("./Types/selectlist_check")
)
const MFC_suggest_address = React.lazy(() => import("./Types/suggest_address"))
const MFC_table = React.lazy(() => import("./Types/table"))
const MFC_group_checkbox = React.lazy(() => import("./Types/group_checkbox"))
const MFC_multi_input = React.lazy(() => import("./Types/multi_input"))
const MFC_signature = React.lazy(() => import("./Types/signature"))

const List = {
  signature: MFC_signature,
  multi_input: MFC_multi_input,
  group_checkbox: MFC_group_checkbox,
  table: MFC_table,
  suggest_address: MFC_suggest_address,
  selectlist_check: MFC_selectlist_check,
  selectlist: MFC_selectlist,
  html_editor: MFC_html_editor,
  readonly_html: MFC_readonly_html,
  textareafull: MFC_textareafull,
  currency: MFCCurrency,
  number: MFCNumber,
  number_decimal: MFCNumberDecimal,
  number_natural: MFCNumberNatural,
  date: MFCDatePicker,
  datepicker: MFCDatePicker,
  select2: MFCSelect2,
  textarea: MFCTextarea,
  text: MFCText,
  alert: MFCAlert,
  password: MFCPassword,
  checkbox: MFC_checkbox,
  readonly: MFC_readonly,
  layout_row: MFC_layout_row,
  layout_grid: MFC_layout_grid,
  layout_group: MFC_layout_group,
  timepicker: MFC_timepicker,
  timepicker_text: MFC_timepicker_text,
  datepicker_text: MFC_datepicker_text,
  arrtext: MFC_arrtext,
  select_tag: MFC_select_tag,
  datetime: MFC_datetimepicker,
  datetimepicker: MFC_datetimepicker,
  select_table_api: MFC_select_table_api,
  custom: MFC_custom,
  checkbox_yesno: MFC_checkbox_yesno,
  checkbox_inbox: MFC_checkbox_inbox,
  percent: MFC_percent,
  range: MFC_range,
  files: MFC_files,
}

const MFCW_ngang = React.lazy(() => import("./WrapControlTypes/ngang"))
const ListWrapLayout = {
  form_ngang: MFCW_ngang,
  form_horizontal: MFCW_ngang,
  form_row: MFCW_ngang,
}
const MyLayoutOne = {
  init() {
    MyFormControlHelpers.addTypesFromListLazy(List)
    MyLayoutHelpers.addTypesFromListLazy(ListWrapLayout)
  },
}

export default MyLayoutOne
