import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MWContent = React.lazy(() => import("./Types/WContent"))
const MUnsupportedMedia = React.lazy(() => import("./Types/UnsupportedMedia"))
/** For Cell */
const MCIncDecNumber = React.lazy(() => import("./Types/Cell/IncDecNumber"))
const MCAddress = React.lazy(() => import("./Types/Cell/Address"))
const MCApi = React.lazy(() => import("./Types/Cell/Api"))
const MCHistoryAction = React.lazy(() => import("./Types/Cell/HistoryAction"))
const MCForm = React.lazy(() => import("./Types/Cell/Form"))
const MCDatetime = React.lazy(() => import("./Types/Cell/Datetime"))
const MCArrangementDocument = React.lazy(
  () => import("./Types/Cell/ArrangementDocument")
)
const MCArrrangementTask = React.lazy(
  () => import("./Types/Cell/ArrrangementTask")
)
const MCArrangementFile = React.lazy(
  () => import("./Types/Cell/ArrangementFile")
)
/** */
const MEditableQQD = React.lazy(() => import("./Types/EditableQQD"))
const MReadonlyQQD = React.lazy(() => import("./Types/ReadonlyQQD"))
const MEditableQQDServices = React.lazy(
  () => import("./Types/EditableQQDServices")
)
const MQQChangeStatus = React.lazy(() => import("./Types/QQChangeStatus"))
const MFixedHeader = React.lazy(() => import("./Types/FixedTitle"))
const MQQEmailTemplate = React.lazy(() => import("./Types/QQEmailTemplate"))
const MBadgeButton = React.lazy(() => import("./Types/BadgeButton"))
const MQQEmailList = React.lazy(() => import("./Types/QQEmailList"))
const MQQSMSTemplate = React.lazy(() => import("./Types/QQSMSTemplate"))
const MQQSMSList = React.lazy(() => import("./Types/QQSMSList"))
const MFormCollapse = React.lazy(() => import("./Types/FormCollapse"))
const MEditableArrangement = React.lazy(
  () => import("./Types/EditableArrangement")
)
const MReadonlyArrangement = React.lazy(
  () => import("./Types/ReadonlyArrangement")
)
const MyUIScrollToTop = React.lazy(() => import("./Types/ScrollToTop"))
const MyUIAttachFiles = React.lazy(() => import("./Types/AttachFiles"))
const MyUISelectFiles = React.lazy(() => import("./Types/SelectFiles"))
const OneAttachFile = React.lazy(() => import("./Types/OneAttachFile"))
const MArrangementAvatar = React.lazy(() => import("./Types/ArrangementAvatar"))
const MControlSuggestion = React.lazy(() => import("./Types/ControlSuggestion"))
const List = {
  wcontent: MWContent,
  unsupportedmedia: MUnsupportedMedia,
  /** For Cell */
  number_decimal: MCIncDecNumber,
  cell_address: MCAddress,
  cell_api: MCApi,
  cell_form: MCForm,
  cell_datetime: MCDatetime,
  cell_history_action: MCHistoryAction,
  cell_arrangement_document: MCArrangementDocument,
  cell_arrangement_task: MCArrrangementTask,
  cell_arrangement_file: MCArrangementFile,
  /** */
  editable_qqd: MEditableQQD,
  editable_qqd_servives: MEditableQQDServices,
  readonly_qqd: MReadonlyQQD,
  qq_change_status: MQQChangeStatus,
  fixed_title: MFixedHeader,
  qq_email_template: MQQEmailTemplate,
  qq_email_list: MQQEmailList,
  badge_button: MBadgeButton,
  qq_sms_template: MQQSMSTemplate,
  qq_sms_list: MQQSMSList,
  form_collapse: MFormCollapse,
  editable_arrangement: MEditableArrangement,
  readonly_arrangement: MReadonlyArrangement,
  scroll_to_top: MyUIScrollToTop,
  attach_files: MyUIAttachFiles,
  select_files: MyUISelectFiles,
  one_attach_file: OneAttachFile,
  arrangement_avatar: MArrangementAvatar,
  control_suggestion: MControlSuggestion,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
