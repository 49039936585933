import React from "react"
import { MyButtonHelpers } from "@macashipo/mlib"

const MB_link = React.lazy(() => import("./Types/link"))
const MB_api = React.lazy(() => import("./Types/api"))
const MB_api_select = React.lazy(() => import("./Types/api_select"))
const MB_form = React.lazy(() => import("./Types/form"))
const MB_add_new = React.lazy(() => import("./Types/add_new"))
const MB_upload = React.lazy(() => import("./Types/upload"))
const MB_print = React.lazy(() => import("./Types/print"))
const MB_switch_view = React.lazy(() => import("./Types/switch_view"))
const MB_switch_request = React.lazy(() => import("./Types/switch_request"))
const MB_modal = React.lazy(() => import("./Types/modal"))
const MB_modal_page = React.lazy(() => import("./Types/modal_page"))
const MB_group = React.lazy(() => import("./Types/group"))
const MB_linktaga = React.lazy(() => import("./Types/linktaga"))
const MB_action_fnList = React.lazy(() => import("./Types/action_fnList"))
const MB_tutor = React.lazy(() => import("./Types/tutor"))
const MB_modal_html = React.lazy(() => import("./Types/modal_html"))
const MB_info_from_api = React.lazy(() => import("./Types/info_from_api"))
const MB_input_qr = React.lazy(() => import("./Types/input_qr"))

//Custom
const MB_select_to_diffview = React.lazy(() =>
  import("./TypesClient/select_to_diffview")
)

const List = {
  input_qr: MB_input_qr,
  info_from_api: MB_info_from_api,
  modal_html: MB_modal_html,
  tutor: MB_tutor,
  action_fnList: MB_action_fnList,
  linktaga: MB_linktaga,
  link: MB_link,
  api: MB_api,
  api_select: MB_api_select,
  form: MB_form,
  add_new: MB_add_new,
  upload: MB_upload,
  print: MB_print,
  switch_view: MB_switch_view,
  switch_request: MB_switch_request,
  modal: MB_modal,
  modal_page: MB_modal_page,
  group: MB_group,
  //custom
  select_to_diffview: MB_select_to_diffview,
}
const MyButtonOne = {
  init() {
    MyButtonHelpers.addTypesFromListLazy(List)
  },
}

export default MyButtonOne
