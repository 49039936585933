import React, { useRef } from "react"
import styled from "styled-components"
import { useCountdown } from "src/core/hook/useCountdown"
import { HUtils } from "@macashipo/mlib"
import { Button } from "reactstrap"
const Wrap = styled.div`
  padding: 1rem;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  & .a-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    & .a-icon {
      font-size: 30px;
      color: #cbc70f;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & .a-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
    margin-top: 1rem;
    & > button {
      margin-left: 5px;
    }
  }
`
const TextDisplay = props => {
  const { days, hours, minutes, seconds } = props
  return (
    <span>
      {days > 0 && `${days} days `}
      {hours > 0 && `${hours} hours `}
      {minutes > 0 && `${minutes} minutes `}
      {seconds > 0 && `${seconds} seconds`}
    </span>
  )
}
const CheckIdleCountDown = props => {
  // const [fu, forceUpdate] = React.useState(0)
  var countSeconds = props.countSeconds || 60
  if (props.isExpired === true) {
    countSeconds = -1
  }
  const fnList = props.fnList || {}

  const targetDate = useRef(
    new Date(new Date().getTime() + countSeconds * 1000)
  )
  const forceStop = useRef(false)
  const [days, hours, minutes, seconds, totalSeconds] = useCountdown(
    targetDate.current,
    {
      stopWhenFinish: true,
      forceStop: forceStop.current,
    }
  )
  if (totalSeconds <= 0) {
    // HUtils.runFuntion(fnList, "hideModal")
    HUtils.runFuntion(fnList, "onTimeout")
  } else {
    HUtils.runFuntion(fnList, "updateRemainSecond", [
      totalSeconds,
      () => {
        console.log("updateExpired")
        if (forceStop.current == false) {
          console.log("need update expired", totalSeconds, forceStop.current)
          forceStop.current = true
          // forceUpdate(fu + 1)
        }
      },
    ])
  }
  console.log("render check idle", totalSeconds)
  return (
    <Wrap>
      <div className="a-body">
        <div className="a-icon">
          <i className="fa fa-clock-o" />
        </div>
        <div className="a-content">
          {totalSeconds > 0 ? (
            <div>Your session is about to expire</div>
          ) : (
            <div>Your session expired</div>
          )}
          {totalSeconds > 0 && (
            <div>
              You will be logged out in{" "}
              <TextDisplay
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
              .
            </div>
          )}
        </div>
      </div>
      {totalSeconds > 0 ? (
        <div className="a-footer">
          <Button
            onClick={() => {
              HUtils.runFuntion(fnList, "onSignOut")
            }}
          >
            Sign me out
          </Button>
          <Button
            color="primary"
            onClick={() => {
              HUtils.runFuntion(fnList, "onKeepSignIn")
              HUtils.runFuntion(fnList, "hideModal")
            }}
          >
            Keep me signed in
          </Button>
        </div>
      ) : (
        <div className="a-footer">
          <Button
            color="primary"
            onClick={() => {
              HUtils.runFuntion(fnList, "onBackToSignIn")
            }}
          >
            Back to sign in
          </Button>
        </div>
      )}
    </Wrap>
  )
}

export default CheckIdleCountDown
