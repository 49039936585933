//more of table cell
export const KMTC = {
  usingI18n: "usingI18n",
  className: "className",
  style: "style",
  styleByValue: "styleByValue",
  styleDisabled: "styleDisabled",
  configIcon: "configIcon",
  configCheckbox: "configCheckbox",
  icon: "icon", // cho select_icon v.v..
  linkTitle: "linkTitle",
  fLinkTitle: "fLinkTitle",
  canEditF: "canEditF",
  fCanEdit: "fCanEdit",
  newWindow: "newWindow",
  showPhoto: "showPhoto",
  maxHeight: "maxHeight",
  maxWidth: "maxWidth",
  noScale: "noScale",
  styleImg: "styleImg",
  link: "link",
  fLink: "fLink",
  color: "color",
  fColor: "fColor",
  min: "min",
  fMin: "fMin",
  max: "max",
  fMax: "fMax",
  styleInput: "styleInput",
  styleControl: "styleControl",
  classNameWithColField: "classNameWithColField",
  classNameDisabled: "classNameDisabled",
  modal: "modal",
  placeHolder: "placeHolder",
  format: "format",
  dateFormat: "dateFormat",
  datetimeFormat: "datetimeFormat",
  dateTextFormat: "dateTextFormat",
  fBorderLeftColor: "fBorderLeftColor",
  configPage: "configPage",
  configApi: "configApi",
  configPagination: "configPagination",
  fSelectValue: "fSelectValue", //for select2_modal_table
  query: "query",
  querySelectValue: "querySelectValue", //for form_modal_table
  queryFromParent: "queryFromParent", //dung trong kieu expand api -> bo sung query lay tu parent
  queryWithQuerySharedPage: "queryWithQuerySharedPage", //boolean: query request bo sung them tu _onePage.getQueryShared()
  clickToSelect: "clickToSelect",
  button: "button",
  cbNormal: "cbNormal", //for checkbox
  cbPositive: "cbPositive", //for checkbox
  cbNegative: "cbNegative", //for checkbox
  configEditIcon: "configEditIcon", //
  configFileIcon: "configFileIcon",
  configTextDisplay: "configTextDisplay", //config text display {fDisplay,fShow,hasIconAndText,fIcon,icon}
  typeAdmin: "typeAdmin", //type danh rieng cho trang admin neu can thiet
  titleConfirm: "titleConfirm",
  titleExpand: "titleExpand",
  confirmMsg: "confirmMsg", //truoc khi click api v.v..
  fConfirmMsg: "fConfirmMsg", //f confirmMsg
  myui: "myui",
  styleByValueField: "styleByValueField", //styleByValueField or logic
  showDisplayWithMyUI: "showDisplayWithMyUI", //custom display bang myui
  showClear: "showClear", //show nut clear
  configClear: "configClear",
  hideWhenEmpty: "hideWhenEmpty",
  hideWhenNull: "hideWhenNull",
  showDeleteRow: "showDeleteRow", //show nut clear row o right top bar
  showPreviewHTML: "showPreviewHTML", //show nut preview HTML
  showModalMultiLanguage: "showModalMultiLanguage", //bổ sung nút mở màn hình cấu hình ngôn ngữ
  configModalMultiLanguage: "configModalMultiLanguage",
  showTestCanEdit: "showTestCanEdit",
  configDisplay: "configDisplay", //bo sung config display cho cac cell show text
  virtualized: "virtualized", //ho tru virtualized true/false
  virtualizedMin: "virtualizedMin", //chuyen qua virtualized khi co min size la bao nhieu
  showSelectedOnFirstRow: "showSelectedOnFirstRow", //show selected item on first row, select modal
  apiCheckShowModal: "apiCheckShowModal", //check show modal o form
  autoOpenLink: "autoOpenLink", // auto open full path
  overideDefaultValues: "overideDefaultValues", //data truyen len se bao gom default Values, hien tai mac dinh se gom default values nen ko su dung nua
  rows: "rows",
  needReExpand: "needReExpand",
  needReloadOptionsAndList: "needReloadOptionsAndList",
  needReloadRow: "needReloadRow",
  needReload: "needReload",
  needReloadList: "needReloadList",
  needReloadTable: "needReloadTable",
  needReloadPage: "needReloadPage",
  needReloadListMain: "needReloadListMain", //bo sung needReloadListMain cho reload tu table mo tu button modal_page
  needReloadOptionsAndListMain: "needReloadOptionsAndListMain", //bo sung for main page
  needSendMsg: "needSendMsg",
  configFormat: "configFormat", //danh cho config format mac dinh ma cell su dung
  configProgress: "configProgress", //danh cho progress
  configPopover: "configPopover", //danh cho co su dung popover
  configTitleTooltip: "configTitleTooltip", //config title tooltip
  canShow: "canShow", //co show hay ko tu more
  fCanShow: "fCanShow",
  hideOfIds: "hideOfIds", //an dua theo Id
  showBlockMobile: "showBlockMobile",
  configWarning: "configWarning", //bo sung show icon warning kem tooltip
  alwayShow: "alwayShow", //luon muon show cell khi thoa man dieu kien hide
  isNegative: "isNegative", //bool, cho phép số âm hay không, nếu là false thì ko cho phép
  noDecimal: "noDecimal",
  acceptType: "acceptType", //file type dc phep upload
  typeInput: "typeInput", //chuyen doi textarea va input
  typeDisabled: "typeDisabled", //chuyen type khac khi disabled
  fValue: "fValue", //dung xac dinh field Value khi muon thay doi
  fText: "fText", //dung xac dinh field Text khi muon thay doi
  hideZero: "hideZero", //an show khi value = 0
  fPhoto: "fPhoto", //link photo o type link
  multi: "multi", //dung cho cac kieu select ho tro multi
  usingText: "usingText", // select_icon
  usingIconFont: "usingIconFont", // select_icon
  styleIcon: "styleIcon", // select_icon
  styleText: "styleText", // select_icon
  styleTextarea: "styleTextarea",
  updateUIRelative: "updateUIRelative", //update UI cell khac sau khi update field, string cach nhau dau ,
  keyControlDateTime: "keyControlDateTime", //luon render child component trong cell khi render cell
  fDefaultValues: "fDefaultValues",
  customDefaultValues: "customDefaultValues",
  excludeDefaultValues: "excludeDefaultValues",
  htmlEditorLib: "htmlEditorLib",
  htmlEditorConfig: "htmlEditorConfig",
  moreProps: "moreProps",
  configForm: "configForm",
  formConfig: "formConfig",
  fConfigForm: "fConfigForm",
  canDeselect: "canDeselect",
  autoTextWB: "autoTextWB", //tu dong set color trang den neu co set bgF
  styleInherit: "styleInherit", //ke thua style tu wrap
  editorAddFields: "editorAddFields", //danh sách field có thể bỏ vào editor
  defaultShowExpand: "defaultShowExpand", //dung cho kieu expand
  maxChar: "maxChar",
  maxCharUnicode: "maxCharUnicode",
  allowOver: "allowOver",
  useCacheForDOMMeasurements: "useCacheForDOMMeasurements", //bat useCacheForDOMMeasurements cho textareafull
  needLoadingFullRow: "needLoadingFullRow", //loading full row khi update
  noReplaceData: "noReplaceData", //danh cho kieu getTextWithData ko muon replace data
  waitApi: "waitApi", // show loading wheen api requesting
  animation: "animation", //name of animation
  customRowBeforeRequest: "customRowBeforeRequest", //custom data row before Request
  needReloadAfterUpdateFields: "needReloadAfterUpdateFields", //bao gom fNeedReloadAfterUpdateFields
}
//more of button
export const KMB = {
  apiCheckShowModal: "apiCheckShowModal",
  acceptType: "acceptType", //upload
  apiUrlAfter: "apiUrlAfter", //upload
  title: "title", //show title cua button
  tooltip: "tooltip", //show tooltip cua button, chua su dung
  fQuery: "fQuery", //su dung fQuery cho cac query request can thiet: modal type list_check
  queryCheckList: "queryCheckList", //list_check_api
  source: "source", //su dung de lay source tu options// modal type list_check
  apiCheckList: "apiCheckList", //su dung de lay api get list modal type list_check_api
  apiSubmit: "apiSubmit", //api submit for list_check_api
  pathData: "pathData", //path Data get list modal type list_check_api
  fText: "fText", //fText convert to Text modal type list_check_api
  fValue: "fValue", //fValue convert to Value modal type list_check_api
  list: "list", //for group
  autoOpenLink: "autoOpenLink", //open link new window
  needShowModalPage: "needShowModalPage", //cho type api sau khi goi api
  driverjs: "driverjs", //config driverjs cho tutor
  html: "html",
  htmlBase64: "htmlBase64", //chuyen html qua dang base64 để xài
  linkOpen: "linkOpen", // them phuong an cho open link after call api thay vi Data.FullPath
}
//more of form
export const KMF = {
  typeWrapControl: "typeWrapControl",
  defaultConfigWrapControl: "defaultConfigWrapControl",
  btnSubmit: "btnSubmit", //config button submit {title}
  btnReset: "btnReset",
  hideFooter: "hideFooter", //an footer
  noValidateDefault: "noValidateDefault", //tat validate mac dinh
  positionButtonsBottom: "positionButtonsBottom", //left right
  moreButtons: "moreButtons", //array button
  usingI18n: "usingI18n",
}
//more of control
export const KMC = {
  disabled: "disabled",
  placeholder: "placeholder",
  typeWrapControl: "typeWrapControl",
  configWrapControl: "configWrapControl",
  multi: "multi",
  select2: "select2", //more props of select2
  flatpickr: "flatpickr", //more props of flatpickr
  items: "items", //danh cho cac kieu layout
  styleWrapControl: "styleWrapControl",
  optionKey: "optionKey",
  isText: "isText", //kieu date text
  isOnlyTime: "isOnlyTime", //only time for date
  conditionShow: "conditionShow", //condition show
  relativeTo: "relativeTo", //relativeTo
  relativeFrom: "relativeFrom", //relativeFrom
  effectedBy: "effectedBy",
  htmlWhenHide: "htmlWhenHide", //html when hide
  hideCondition: "hideCondition",
  conditionHide: "conditionHide", //tuong tu voi hideCondition
  defaultValue: "defaultValue", //defaultValue trong truong hop muon co default value rieng
  colorType: "colorType", //color type dung cho cai kieu su dung warning/success/danger
  typeInput: "typeInput", // tuong tu typeInput ben cell
  apiMethod: "apiMethod",
  apiUrl: "apiUrl", //goi api
  mapFields: "mapFields", //dung trong kieu select_table_api
  extraData: "extraData", //dung mo ta extraData khi co table
  canAddNewRow: "canAddNewRow", //show add new row
  defaultObjNewRow: "defaultObjNewRow", //obj default khi add new
  maxRow: "maxRow", //max row can add new
  titleAddNewRow: "titleAddNewRow",
  configClientTable: "configClientTable", //config Table
  pathData: "pathData",
  classControl: "classControl",
  appendComponent: "appendComponent",
  prependComponent: "prependComponent",
  configAppend: "configAppend",
  configPrepend: "configPrepend",
  hint: "hint",
  required: "required",
  showRequiredMsg: "showRequiredMsg",
  requiredMsg: "requiredMsg",
  invalidMsg: "invalidMsg", //msg show khi bi invalid
  step: "step",
  sameChange: "sameChange", //se thay doi gia tri field tuong ung voi field o OptionItem {fNeedChange:fInOptionItem}
  hideVisibility: "hideVisibility", // ==true se hide bang cach gan style hideVisibility = hidden
  noNegative: "noNegative",
  noDecimal: "noDecimal",
  maxData: "maxData", //dung cho cai kieu select show ra bi chan neu data bi nhieu
  styleBase: "styleBase", //style cua Base control
  style: "style",
  showSearch: "showSearch",
  htmlEditorLib: "htmlEditorLib",
  morePropsCK5: "morePropsCK5",
  hideZero: "hideZero",
  hideNull: "hideNull", //khi gia tri default la null thi show rỗng thay vi để số 0
  min: "min",
  max: "max",
  prefix: "prefix",
  suffix: "suffix",
  theme: "theme",
  allowEnterToSubmit: "allowEnterToSubmit",
  submitOnChange: "submitOnChange",
  clickToSelect: "clickToSelect",
  usingColorOptions: "usingColorOptions",
  configApiSourceData: "configApiSourceData",
}
const moreOfNumeral = [
  KMC.hideZero,
  KMC.hideNull,
  KMC.min,
  KMC.max,
  KMC.prefix,
  KMC.suffix,
  KMC.allowEnterToSubmit,
  KMC.noDecimal,
]
export const FlowGetMoreForNumeral = (more = {}) => {
  let _more = {}
  for (let k of moreOfNumeral) {
    if (more.hasOwnProperty(k)) {
      _more[k] = more[k]
    }
  }
  return _more
}

export const FlowGetStringOfMoreFromRow = ({ key, row, more, df } = {}) => {
  let _value = df
  let _fKey = `f${key.charAt(0).toUpperCase() + key.slice(1)}`
  if (row && more[_fKey] && row.hasOwnProperty(more[_fKey])) {
    _value = row[more[_fKey]]
  } else if (more.hasOwnProperty(key)) {
    _value = more[key]
  }
  return _value
}

export const FlowGetBoolOfMoreFromRow = ({ key, row, more, df } = {}) => {
  let _value = df || false
  let _fKey = `f${key.charAt(0).toUpperCase() + key.slice(1)}`
  if (row && more[_fKey] && row.hasOwnProperty(more[_fKey])) {
    _value = row[more[_fKey]]
  } else if (more.hasOwnProperty(key)) {
    _value = more[key]
  }
  return _value
}

export const FlowGetConfigOfMoreFromRow = ({
  key,
  row,
  more,
  df,
  parseConfig,
} = {}) => {
  let _config = { ...df }
  if (key && key.length > 0) {
    let _configKey = `config${key.charAt(0).toUpperCase() + key.slice(1)}`
    let _fKey = `f${key.charAt(0).toUpperCase() + key.slice(1)}`
    if (row && more[_fKey] && row.hasOwnProperty(more[_fKey])) {
      _config.value = row[more[_fKey]]
      _config.show = true
    } else if (more.hasOwnProperty(key)) {
      _config.value = more[key]
      _config.show = true
    }

    if (more[_configKey]) {
      if (parseConfig) {
        parseConfig({ config: more[_configKey], row })
      }
      _config = { ..._config, ...more[_configKey] }
      if (
        row &&
        more[_configKey][_fKey] &&
        row.hasOwnProperty(more[_configKey][_fKey])
      ) {
        _config.value = row[more[_configKey][_fKey]]
        _config.show = true
      } else if (more[_configKey].hasOwnProperty(key)) {
        _config.value = more[_configKey][key]
        _config.show = true
      }
    }
  }
  return _config
}
