import React, { Fragment } from "react"
import { connect } from "react-redux"
import classnames from "classnames"
import { withRouter } from "react-router-dom"
import AppMain from "./AppMain"
import ResizeDetector from "react-resize-detector"
import "./assets/base.css"
import { ErrorBoundary } from "src/libs/ReactErrorBoundary"
import styled from "styled-components"
import { Button } from "reactstrap"
import ImgIconError from "src/assets/images/icon-error.png"
import { HLocalStorage } from "@macashipo/mlib"
const WrapError = styled.div`
  margin: 0 auto;
  width: 300px;
  margin-top: 2rem;
  min-width: 300px;
  max-width: 100%;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  .a-img {
    text-align: center;
    & img {
      width: 100px;
      height: auto;
    }
  }
  & .a-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  & .a-msg {
    text-align: center;
    white-space: pre-wrap;
  }
  & .a-buttons {
    > button {
      margin-right: 5px;
    }
  }
`
function ErrorFallback({ error, resetErrorBoundary }) {
  if (error && error.message) {
    // let _sNeedReload = "img is a void"//test
    let _sNeedReload = "Loading chunk"
    if (window.sendGGNotifyLog) {
      let _msg = `${error.message}\n${String(error.stack).slice(0, 300)}`
      if (navigator && navigator.userAgent) {
        _msg += `\nAgent: ${navigator.userAgent}`
      }
      window.sendGGNotifyLog({ msg: _msg })
    }
    if (error.message.indexOf(_sNeedReload) > -1) {
      let _justReloadOne = HLocalStorage.getString("_justReloadOne")
      let _currentTime = new Date().getTime()
      let _needReload = true
      if (_justReloadOne) {
        if (_currentTime - parseInt(_justReloadOne) < 1000 * 10) {
          //10s
          _needReload = false
        }
      }
      console.warn("reload one", _needReload)
      if (_needReload) {
        HLocalStorage.saveString("_justReloadOne", String(_currentTime))
        window.location.reload()
      }
    }
  }
  return (
    <WrapError role="alert">
      <div className="a-img">
        <img src={ImgIconError} alt="Error" />
      </div>
      <div className="a-title">Oh no!</div>
      <p className="a-msg">
        {`Sorry! Something went wrong.
        Please try again later.`}
      </p>
      <pre>{error.message}</pre>
      <div className="a-buttons">
        <Button color="primary" onClick={resetErrorBoundary}>
          Try again
        </Button>
        <Button
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload
        </Button>
      </div>
    </WrapError>
  )
}
class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedSmallerSidebar: false,
      width: undefined,
    }
  }

  onResize = width => this.setState({ width })

  render() {
    const { width } = this.state

    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      // enablePageTabsAlt,
      children,
    } = this.props

    return (
      <Fragment>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <div
            className={classnames(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1200 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1200 },
              { "closed-sidebar-mobile": closedSmallerSidebar || width < 1200 },
              { "sidebar-mobile-open": enableMobileMenu }
            )}
          >
            <AppMain>{children}</AppMain>
            <ResizeDetector handleWidth onResize={this.onResize} />
          </div>
        </ErrorBoundary>
      </Fragment>
    )
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
})

export default withRouter(connect(mapStateToProp)(Main))
