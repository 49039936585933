import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const PTableListCard = React.lazy(() => import("./Types/TableListCard"))
const PageEditableQQD = React.lazy(() => import("./Types/PageEditableQQD"))
const PageReadonlyQQD = React.lazy(() => import("./Types/PageReadonlyQQD"))
const PageHistoryAction = React.lazy(() => import("./Types/PageHistoryAction"))
const PageEditableArrangement = React.lazy(
  () => import("./Types/PageEditableArrangement")
)
const PageHistoryActionOverview = React.lazy(
  () => import("./Types/PageHistoryActionOverview")
)
const PageDocumentOverview = React.lazy(
  () => import("./Types/PageDocumentOverview")
)
const PageReadonlyArrangement = React.lazy(
  () => import("./Types/PageReadonlyArrangement")
)
const PageTaskOverview = React.lazy(() => import("./Types/PageTaskOverview"))
const PageDocumentFilesOverview = React.lazy(
  () => import("./Types/PageDocumentFilesOverview")
)
const PageARowForm = React.lazy(() => import("./Types/PageARowForm"))
/** Base V5 */
const PAdminTableV5 = React.lazy(
  () => import("./Types/PageCustomV5/admin_table")
)

const List = {
  r_table_list_card: PTableListCard,
  editable_quick_quote_detail: PageEditableQQD,
  readonly_quick_quote_detail: PageReadonlyQQD,
  history_action: PageHistoryAction,
  admin_table_custom: PAdminTableV5,
  editable_arrangement_detail: PageEditableArrangement,
  history_action_overview: PageHistoryActionOverview,
  document_overview: PageDocumentOverview,
  readonly_arrangement_detail: PageReadonlyArrangement,
  task_overview: PageTaskOverview,
  document_files_overview: PageDocumentFilesOverview,
  a_row_form: PageARowForm,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
