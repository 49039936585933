import React from "react"
import { MyPageHelpers } from "@macashipo/mlib"

const MyPageWelcome = React.lazy(() => import("./Types/welcome"))
const MP_iframe = React.lazy(() => import("./Types/iframe"))
const MyPageAdminTab = React.lazy(() => import("./Types/admin_tab"))
const MyPageAdminTable = React.lazy(() => import("./Types/admin_table"))
const MyPageAdminPanel = React.lazy(() => import("./Types/admin_panel"))
const MyPageAdminPanelGrid = React.lazy(() =>
  import("./Types/admin_panel_grid")
)
const MyPageAdminTableClient = React.lazy(() =>
  import("./Types/admin_table_client")
)
const MyPageAdminPageNotFound = React.lazy(() =>
  import("./Types/admin_pagenotfound")
)
const MyPageWithMyUI = React.lazy(() => import("./Types/with_myui"))
const MyPageAdminDashboard = React.lazy(() => import("./Types/admin_dashboard"))
const MyPageAdminList = React.lazy(() => import("./Types/admin_table_list"))
const MyPageAdminListCard = React.lazy(() =>
  import("./Types/admin_table_list_card")
)
const MyPageAdminGridCard = React.lazy(() =>
  import("./Types/admin_table_grid_card")
)
const MP_API_Document = React.lazy(() => import("./Types/api_document"))
const MyPageMyProfile = React.lazy(() => import("./Types/myprofile"))
const MP_MyProfileSignatureEmail = React.lazy(() =>
  import("./Types/myprofile_signature_email")
)
const MP_MyProfileSignaturePhoto = React.lazy(() =>
  import("./Types/myprofile_signature_photo")
)
const MyPageApiRequest = React.lazy(() => import("./Types/api_request"))
const MP_redirect = React.lazy(() => import("./Types/redirect"))
const MP_mobile_dashboard = React.lazy(() => import("./Types/mobile_dashboard"))
const MP_chart_chartjs = React.lazy(() => import("./Types/chart_chartjs"))
const MP_chart = React.lazy(() => import("./Types/chart"))
const MP_grid = React.lazy(() => import("./Types/grid"))
const MP_wrap = React.lazy(() => import("./Types/wrap"))
const MP_calendar = React.lazy(() => import("./Types/calendar"))
const MP_dashboard_one = React.lazy(() => import("./Types/dashboard_one"))
const MP_flow = React.lazy(() => import("./Types/flow"))
const MP_html = React.lazy(() => import("./Types/html"))
const MP_admin_tab_client = React.lazy(() => import("./Types/admin_tab_client"))

// Report
const MP_report = React.lazy(() => import("./Types/report"))
const MP_report_html = React.lazy(() => import("./TypesReport/report_html"))
const MP_report_array_html = React.lazy(() =>
  import("./TypesReport/report_array_html")
)
const MP_report_array_photo = React.lazy(() =>
  import("./TypesReport/report_array_photo")
)
const MP_report_table = React.lazy(() => import("./TypesReport/report_table"))
const MP_report_chart = React.lazy(() => import("./TypesReport/report_chart"))
const MP_report_signature = React.lazy(() =>
  import("./TypesReport/report_signature")
)
const MP_report_layout = React.lazy(() => import("./TypesReport/report_layout"))
const MP_report_multi_table = React.lazy(() =>
  import("./TypesReport/report_multi_table")
)
const MP_report_title_browser = React.lazy(() =>
  import("./TypesReport/report_title_browser")
)
//Dashboard
const MP_widget = React.lazy(() => import("./Types/widget"))
const MP_multi_table = React.lazy(() => import("./Types/multi_table"))
const MP_survey = React.lazy(() => import("./Types/survey"))

const List = {
  survey: MP_survey,
  multi_table: MP_multi_table,
  widget: MP_widget,
  //report
  report: MP_report,
  report_title_browser: MP_report_title_browser,
  report_html: MP_report_html,
  report_table: MP_report_table,
  report_array_html: MP_report_array_html,
  report_array_photo: MP_report_array_photo,
  report_chart: MP_report_chart,
  report_signature: MP_report_signature,
  report_layout: MP_report_layout,
  report_multi_table: MP_report_multi_table,
  admin_tab_client: MP_admin_tab_client,
  html: MP_html,
  flow: MP_flow,
  dashboard_one: MP_dashboard_one,
  calendar: MP_calendar,
  grid: MP_grid,
  wrap: MP_wrap,
  chart_chartjs: MP_chart_chartjs,
  chart: MP_chart,
  admin_tab: MyPageAdminTab,
  admin_table: MyPageAdminTable,
  admin_panel: MyPageAdminPanel,
  admin_pagenotfound: MyPageAdminPageNotFound,
  admin_table_client: MyPageAdminTableClient,
  admin_dashboard: MyPageAdminDashboard,
  admin_table_list: MyPageAdminList,
  admin_table_list_card: MyPageAdminListCard,
  admin_table_grid_card: MyPageAdminGridCard,
  welcome: MyPageWelcome,
  with_myui: MyPageWithMyUI,
  //default type
  panel: MyPageAdminPanel,
  panel_grid: MyPageAdminPanelGrid,
  table: MyPageAdminTable,
  tab: MyPageAdminTab,
  table_list: MyPageAdminList,
  table_list_card: MyPageAdminListCard,
  table_grid_card: MyPageAdminGridCard,
  myprofile: MyPageMyProfile,
  myprofile_signature_email: MP_MyProfileSignatureEmail,
  myprofile_signature_photo: MP_MyProfileSignaturePhoto,
  // api_document: MP_API_Document,
  api_request: MyPageApiRequest,
  iframe: MP_iframe,
  redirect: MP_redirect,
  mobile_dashboard: MP_mobile_dashboard,
}
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List)
  },
}

export default MyPageOne
