import { HModules, HCF, HLink, HUtils, CSuppense } from "@macashipo/mlib"
import {
  MyUIOne,
  MyPageOne,
  MyControlOne,
  MyFormControlOne,
  MyCardOne,
  MyTableCellOne,
  MyControlForm,
  MyLibOne
} from "./components"
import "./assets/theme-crmhf.scss"
const ModuleAppCRMHF = {
  _key: "appcrmhf",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "appcrmhf"
  },
  runAfterInit: HConfig => {
    window.document.body.classList.add("theme-crmhf")
    HModules.initDefault(ModuleAppCRMHF, {
      MyLibOne,
      MyUIOne,
      MyPageOne,
      MyControlOne,
      MyFormControlOne,
      MyCardOne,
      MyTableCellOne,
      MyControlForm,
    })
    const userAgent =
      window?.navigator?.userAgent && window.navigator.userAgent.toLowerCase()
      if (userAgent && userAgent.indexOf("iphone") > -1) {
        window.document.body.classList.add("on-ios")
      }
    CSuppense.LazyLoading = () => {
      return (
        <div className="mui-lazy-loading ani-fadeIn">
          <i className="fa fa fa-circle-o-notch fa-spin" />
        </div>
      )
    }
  },
}

export default ModuleAppCRMHF
