import {
  MyIcon,
  HIs,
  HUtils,
  MyUI,
  HCF,
  HJson,
  HFormat,
  MyTableCell,
  HColor,
} from "@macashipo/mlib"
import * as FlowTableExtraData from "./TableExtraData"
import * as FlowApi from "./Api"
import { ApiGeneric } from "src/services/apiServices"
import { fnListMyPage, fnListUI } from "src/helpers/fnList"
import {
  KConfigApp,
  KConfigModal,
  KConfigPageTable,
  KOnePageMore,
  kClientKeyUI,
} from "../K"
import { FlowGetBoolOfMoreFromRow, KMTC } from "./More"
import { FlowTestResponseFullPath } from "./Test"
import { HUtils_getTextOrHTML } from "./MNeedUpdateMLib"
export const KMore = KMTC
export const KMoreOfTableCell = KMTC

export const KConfigInRow = {
  _UI_Loading: "_UI_Loading",
  _UI_Error: "_UI_Error",
  _UI_NeedUpdateUI: "_UI_NeedUpdateUI", //true: luon render lai ui
}

const KTypeCellShared = {
  _cell_loading: "_cell_loading",
}

export const FlowGetExtraDataFromExtra = (extra = {}) => {
  return extra.extraData || {}
}
export const FlowGetFieldNameFromExtra = (extra = {}) => {
  return extra.fieldName
}
export const FlowGetExtraOfFieldName = (extra = {}, fieldName) => {
  let _extraCol = extra
  if (fieldName && extra.fieldName != fieldName) {
    _extraCol = FlowTableExtraData.FlowGetColumnFromExtraData(
      FlowGetExtraDataFromExtra(extra),
      fieldName
    )
  }
  return _extraCol
}
export const FlowGetMore = ({ extra, more, fieldName }) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  return (
    more ||
    FlowTableExtraData.FlowGetMoreOfCellFromExtraData(
      FlowGetExtraDataFromExtra(extra),
      _fieldName
    )
  )
}

//////// More
export const FlowGetCanShow = ({
  extra,
  row,
  more,
  fieldName,
  defaultCanShow,
}) => {
  let _canShow = defaultCanShow != null ? defaultCanShow : true
  let _more = FlowGetMore({ extra, more, fieldName })
  // console.log("FlowGetCanShow")
  if (_more) {
    if (_canShow != _more[KMore.canShow]) {
      _canShow = _more[KMore.canShow]
    }
    if (
      _more[KMore.fCanShow] &&
      row[_more[KMore.fCanShow]] != null &&
      _canShow != row[_more[KMore.fCanShow]]
    ) {
      _canShow = row[_more[KMore.fCanShow]]
    }
    if (_more[KMore.hideOfIds] && _more[KMore.hideOfIds].indexOf(row.Id) > -1) {
      _canShow = false
    }
  }
  return _canShow
}
export const FlowActionClickApi = ({ extra, more, fieldName }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
}
export const FlowGetFValue = ({ extra, row, more, fieldName }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.fValue]) {
    return row[_more[KMore.fValue]]
  }
  return "Value"
}
export const FlowGetFText = ({ extra, row, more, fieldName }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.fText]) {
    return row[_more[KMore.fText]]
  }
  return "Text"
}
export const FlowActionClickForm = () => {}
export const FlowActionClickLink = ({ extra, row, more, fieldName, link }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _link = link || FlowGetLink({ extra, row, more: _more, fieldName })
  if (_link) {
    window.open(
      _link,
      FlowGetLinkTarget({ extra, row, more: _more, fieldName })
    )
  }
  console.warn("Click link", _link)
}

// 18/11/2023: Ngưng oneTableCell.parseResponse, chuyển qua FlowApi.FlowApiShowFullPath
export const FlowParseResponseAndNeedReload = ({
  extra,
  more,
  row,
  fieldName,
  oneTableCell,
  response,
  configApi,
  fnList,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  console.warn("parseResponse", oneTableCell)
  if (oneTableCell) {
    let _configApi =
      configApi || oneTableCell.getConfigApi({ customMore: more })
    ////Test FlowTestResponseFullPath
    // FlowTestResponseFullPath(response)
    ////End Test
    //// oneTableCell.parseResponse(response) //chuyển qua FlowApi.FlowApiShowFullPath
    FlowApi.FlowApiShowFullPath({
      response: response,
      configFullPath: response.Data,
      autoOpenLink:
        _more[KMore.autoOpenLink] != null ? _more[KMore.autoOpenLink] : null,
    })
    //chua xu ly more.needReloadOptionsAndList ben trong nen chuyen ra ngoai xu ly
    // oneTableCell.parseNeedReload(_configApi, response)
    if (_configApi) {
      if (fnList) {
        if (_configApi.needReloadPage === true) {
          fnList.onReloadPage && fnList.onReloadPage()
          return
        }

        if (_configApi.needReloadOptionsAndList) {
          fnList.fnReloadOptionsAndList && fnList.fnReloadOptionsAndList()
        } else if (_configApi.needReloadList === true) {
          fnList.onReloadList && fnList.onReloadList()
        }

        if (_configApi.needSendMsg === true) {
          fnList.onSendMsg && fnList.onSendMsg(_configApi.needSendMsg)
        }
        if (_configApi.needReExpand === true) {
          fnList.onReExpand && fnList.onReExpand()
        }
      }

      if (
        _configApi.needReloadRow &&
        response &&
        response.Data &&
        response.Data.Id === row.Id
      ) {
        fnList.onUpdateRow &&
          fnList.onUpdateRow({
            row: row,
            newRow: response.Data,
          })
      }
    }
  }
  if (_more) {
    if (_more[KMore.needReloadOptionsAndListMain]) {
      let _fnReloadOptionsAndListOfMain = HUtils.get(
        fnList,
        "fnListOfPage.fnListOfButton.fnListOfPage.fnReloadOptionsAndList"
      )
      if (_fnReloadOptionsAndListOfMain) {
        _fnReloadOptionsAndListOfMain()
      }
    } else if (_more[KMore.needReloadListMain]) {
      let _fnReloadListOfMain = HUtils.get(
        fnList,
        "fnListOfPage.fnListOfButton.fnListOfPage.fnReloadList"
      )
      if (_fnReloadListOfMain) {
        _fnReloadListOfMain()
      }
    }
  }
}
export const FlowCheckShowModal = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _configModal = _more[KMore.modal]
  let _canShowModal = defaultValue != null ? defaultValue : true
  if (_configModal) {
    if (_configModal.canShowModal == false) {
      _canShowModal = false
    }
    if (
      _configModal.fCanShowModal &&
      row[_configModal.fCanShowModal] === false
    ) {
      _canShowModal = false
    }
  }
  return _canShowModal
}

export const FlowGetRequestFromConfigObj = ({ row, configApi } = {}) => {
  let _configApi = configApi || {}
  let _request = {
    method: "POST" || _configApi.method,
    url: _configApi.url,
    path: _configApi.path,
    name: _configApi.name,
  }
  //for old style v1 request inside configApi
  if (_configApi.request) {
    _request = { ..._request, ..._configApi.request }
  }

  if (_request.url) {
    if (_request.url.indexOf("[") > -1) {
      _request.url = HUtils.getTextWithData(_request.url, row)
    }
  }
  if (_request.name) {
    if (_request.name.indexOf("[") > -1) {
      _request.name = HUtils.getTextWithData(_request.name, row)
    }
  }

  // console.log("_request:", _request)
  return _request
}

export const FlowGetRequestQueryFromConfigObj = ({
  row,
  query,
  queryByField,
  configApi,
  df,
}) => {
  let _query = df || {}
  if (query) {
    _query = Object.assign(_query, query)
    let _keys = Object.keys(_query)
    for (let k of _keys) {
      let _value = query[k]
      if (row.hasOwnProperty(_value) == true) {
        if (row[_value] != null) {
          _query[k] = row[_value]
        }
      }
    }
  }
  //queryByField old v1 get from row only
  if (queryByField) {
    for (let k of Object.keys(queryByField)) {
      _query[k] = row[queryByField[k]]
    }
  }
  //
  if (configApi != null) {
    let _queryFromConfigApi = FlowGetRequestQueryFromConfigObj({
      row,
      query: configApi.query,
      queryByField: configApi.queryByField,
    })
    _query = { ..._query, ..._queryFromConfigApi }
  }
  return _query
}

export const FlowCheckApiShowModal = ({
  extra,
  more,
  row,
  fieldName,
  cb,
  fnList,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _configCheckShow = _more[KMore.apiCheckShowModal]
  let _canShowModal = FlowCheckShowModal({ extra, more, row })
  //test
  // FlowApi.FlowApiShowFullPath({
  //   response: {},
  //   configFullPath: { FullPath: "http://google.com" },
  // })
  // cb({
  //   canShow: true,
  // })
  // return
  //
  //clear focus button
  document.activeElement.blur()
  if (cb) {
    if (_canShowModal == false) {
      cb({
        canShow: false,
      })
    } else if (_configCheckShow) {
      let _request = FlowGetRequestFromConfigObj({
        row,
        configApi: _configCheckShow,
      })
      let _query = FlowGetRequestQueryFromConfigObj({
        row,
        query: _configCheckShow.query,
      })
      ApiGeneric({
        request: _request,
        data: _query,
        successCallBack: response => {
          let _config = HUtils.get(response, "Data.Config")
          if (_config) {
            FlowApi.FlowApiShowFullPath({
              response,
              configFullPath: _config,
              autoOpenLink:
                more[KMore.autoOpenLink] != null
                  ? more[KMore.autoOpenLink]
                  : null,
            })
          }
          if (_config && _config.IsShow) {
            //muc dinh khi click form -> update row -> show tren ui
            //pos -> user click btn -> chuyen tu order qua bill -> ko can show form
            let _resultModel = HUtils.get(response, "Data.Config.ResultModel")
            if (_resultModel && row) {
              //update model vao row
              HUtils.runFuntion(fnList, "onUpdateRow", [
                {
                  row,
                  newRow: _resultModel,
                },
              ])
            }
            if (cb) {
              cb({
                canShow: true,
                config: _config,
                data: _config.FormData,
                configFormFromServer: _config.ConfigForm, //chưa xài, chưa test, bổ sung sẵn
              })
            }
          } else {
            if (cb) {
              cb({
                canShow: false,
              })
            }
          }
          // oneButton.changeIsLoading(false)
        },
        errorCallBack: (error, response) => {
          // oneButton.changeIsLoading(false)
          if (cb) {
            cb({
              canShow: false,
            })
          }
        },
      })
    } else {
      cb({
        canShow: true,
      })
    }
  }
  return false
}
export const FlowCheckConfirmMsg = ({ extra, more, row, fieldName, cb }) => {
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  let _confirmMsg = _more[KMore.confirmMsg]
  let _confirmTitle = _more[KMore.titleConfirm] || "Confirm"
  if (row && _more[KMore.fConfirmMsg] && row[_more[KMore.fConfirmMsg]]) {
    _confirmMsg = row[_more[KMore.fConfirmMsg]]
  }
  if (_confirmMsg) {
    fnListUI.fnshowConfirm({
      title: _confirmTitle,
      msg: _confirmMsg,
      onClickOK: () => {
        if (cb) {
          cb()
        }
      },
    })
  } else {
    if (cb) {
      cb()
    }
  }
}
export const FlowGetConfigButton = ({ extra, more, row, fieldName }) => {
  let _configButton = {}
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  if (_more[KMore.button]) {
    _configButton = _more[KMore.button]
  }
  return _configButton || {}
}
export const FlowGetMorePropsButton = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
  forceValue,
}) => {
  let _moreProps = defaultValue || {}
  let _configButton = FlowGetConfigButton({ extra, more, row, fieldName }) || {}
  if (_configButton.style) {
    _moreProps.style = { ..._moreProps.style, ..._configButton.style }
  }
  if (HIs.isBoolean(_configButton.outline)) {
    _moreProps.outline = _configButton.outline
  }
  if (_configButton.color != null) {
    _moreProps.color = _configButton.color
  }
  if (_configButton.colorByValue != null) {
    if (row[fieldName]) {
      if (_configButton.colorByValue[row[fieldName]]) {
        _moreProps.color = _configButton.colorByValue[row[fieldName]]
      }
    }
  }
  if (_configButton.className != null) {
    _moreProps.className = _configButton.className
  }
  //titleTooltip
  // console.warn(
  //   "config titleTooltip:",
  //   more,
  //   _configButton,
  //   _configButton.titleTooltip
  // )
  if (_configButton.fTitleTooltip != null) {
    _moreProps.title = row[_configButton.fTitleTooltip] || ""
  } else if (_configButton.titleTooltip != null) {
    if (_configButton.titleTooltip.indexOf("[") > -1) {
      _moreProps.title = HUtils.getTextWithData(_configButton.titleTooltip, row)
    } else {
      _moreProps.title = _configButton.titleTooltip
    }
  }
  if (HIs.isBoolean(_configButton.disabled)) {
    _moreProps.disabled = _configButton.disabled
  }
  if (_configButton.fDisabled != null) {
    if (typeof _configButton.fDisabled == "boolean") {
      _moreProps.disabled = _configButton.fDisabled
    } else if (row && typeof row[_configButton.fDisabled] == "boolean") {
      FlowUpdateRelativeByField({
        extra,
        fieldName,
        fEdit: _configButton.fDisabled,
        row,
        checkChange: true,
      })
      _moreProps.disabled = row[_configButton.fDisabled]
    }
  }
  if (forceValue) {
    _moreProps = { ..._moreProps, ...forceValue }
  }
  // console.log("FlowGetMorePropsButton", _moreProps, more)
  return _moreProps
}
export const FlowGetConfigMyUI = ({ extra, more, row, fieldName }) => {
  let _configMyUI = {}
  let _more = FlowGetMore({ extra, more, fieldName }) || {}
  if (_more[KMore.myui]) {
    _configMyUI = _more[KMore.myui]
  }
  return _configMyUI
}
//thay the TableHelper render icon
export const FlowRenderIconFromMore = ({
  extra,
  more,
  fieldName,
  isActive,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _isActive = isActive !== false
  if (_more) {
    let _icon = _more[KMore.configIcon] || _more[KMore.icon]
    if (_icon) {
      if (HIs.isString(_icon)) {
        return <MyIcon data={_icon} />
      } else if (HIs.isObject(_icon)) {
        if (_icon.hideWhenNoActive && _isActive == false) {
          return null
        }
        let _style = {
          ..._icon.style,
          ...(_isActive === false ? _icon.styleInactive : {}),
        }
        if (_icon.className) {
          return <MyIcon data={_icon.className} style={_style} />
        }
      }
    }
  }
}
export const FlowGetScreenCodeFromCell = ({ extra, fnList }) => {
  if (extra && extra["_ScreenCode"]) {
    return extra["_ScreenCode"]
  }
  let _screenCode = fnListMyPage.fnGetScreenCode(fnList)
  if (_screenCode) {
    extra["_ScreenCode"] = _screenCode
  }
  return _screenCode
}
export const FlowGetTextOrHTMLWithI18n = (
  text,
  { extra, more, fieldName, fnList, from } = {}
) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMTC.usingI18n]) {
    let _ns = FlowGetScreenCodeFromCell({ extra, fnList })
    return HUtils_getTextOrHTML(text, {
      usingI18n: true,
      ns: _ns,
      from: from || "cell",
    })
  }
  return text
}
export const FlowGetTextDisplay = ({
  extra,
  more,
  row,
  fieldName,
  oneTableCell,
  cell,
  valueCell,
  isButton,
}) => {
  if (oneTableCell) {
    let _configTextDisplay = { ...more }
    if (isButton) {
      _configTextDisplay = FlowGetConfigTextDisplayOfButton({
        row,
        extra,
        more,
        fieldName,
      })
    }
    // console.warn("_configTextDisplay:", _configTextDisplay, row)
    //luu y truong hop muon display empty, nhung cell co gia tri thi hien tai lib dang tra ve gia tri cell
    return oneTableCell.getTextDisplay(_configTextDisplay)
  }
  return cell || valueCell
}

export const FlowGetCustomDisplay = ({
  extra,
  more,
  row,
  fieldName,
  oneTableCell,
  props,
  value,
  defaultConfigDisplay,
  placeHolder,
}) => {
  // console.log("FlowGetCustomDisplay", placeHolder)
  if (more && more[KMore.showDisplayWithMyUI]) {
    const configMyUI = FlowGetConfigMyUI({ extra, more, fieldName })
    if (configMyUI && configMyUI.type) {
      return (
        <div>
          <MyUI
            {...props}
            type={configMyUI.type || "notypemyui"}
            data={configMyUI.dataIsRow ? row : value}
            row={row}
            config={configMyUI}
            {...configMyUI.moreProps}
          />
        </div>
      )
    }
  }
  let _valueDisplay = value
  if (typeof value == "string") {
    let _configDisplay = more[KMore.configDisplay] || defaultConfigDisplay
    if (_configDisplay) {
      if (_configDisplay.showShortText) {
        let _countShort = _configDisplay.countShort || 50
        _valueDisplay = value.slice(0, _countShort)
        _valueDisplay += `${_valueDisplay.length == _countShort ? "..." : ""}`
      } else if (_configDisplay.text) {
        _valueDisplay = HUtils.getTextWithData(_configDisplay.text, row)
      } else if (_configDisplay.configFormat) {
        _valueDisplay = HFormat.format(_valueDisplay, {
          ..._configDisplay.configFormat,
          row: row,
        })
      } else if (_configDisplay.isHide) {
        _valueDisplay = ""
      }
    }
  }
  if ((placeHolder && _valueDisplay == null) || String(_valueDisplay) == "") {
    return (
      <div
        style={{
          color: "var(--color-placeholder)",
        }}
      >
        {placeHolder}
      </div>
    )
  }
  return <div>{String(_valueDisplay)}</div>
}

export const FlowGetConfigTextDisplayOfButton = ({
  row,
  extra,
  more,
  fieldName,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _config = { ..._more, customMore: _more }
  if (_more) {
    let _configButton = more[KMore.button]
    if (_configButton) {
      if (_configButton.hasOwnProperty("icon")) {
        _config.icon = _configButton.icon
      }
      if (
        _configButton.hasOwnProperty("iconByValue") &&
        row &&
        row[fieldName]
      ) {
        _config.icon = _configButton.iconByValue[row[fieldName]]
      }
      if (_configButton.hasOwnProperty("title")) {
        _config.title = _configButton.title
      }
      if (_configButton.hasOwnProperty("hasIconAndText")) {
        _config.hasIconAndText = _configButton.hasIconAndText
      }
    }
  }
  return _config
}

export const FlowRenderStatus = ({
  extra,
  more,
  fieldName,
  row,
  valueCell,
  link,
  canEdit,
}) => {
  let _ui = []
  if (more) {
    if (
      more[KMore.configEditIcon] &&
      more[KMore.configEditIcon].using &&
      canEdit
    ) {
      _ui.push(
        <MyTableCell extra={extra} row={row} more={more} type="_editIcon" />
      )
    }
  }
  if (_ui.length > 0) {
    return <div className="mct-status ag-np"></div>
  }
}

export const FlowRenderTitleOfLink = ({
  extra,
  more,
  fieldName,
  row,
  valueCell,
  link,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.showPhoto] === true) {
    let _maxHeight = "120px"
    let _maxWidth = "120px"
    if (_more[KMore.maxHeight]) {
      _maxHeight = _more[KMore.maxHeight]
    }
    if (_more[KMore.maxWidth]) {
      _maxWidth = _more[KMore.maxWidth]
    }
    let _styleImg = { width: "100%", height: "100%", maxHeight: _maxHeight }
    if (_more[KMore.noScale] == true) {
      _styleImg = {
        maxHeight: _maxHeight,
        display: "block",
        maxWidth: _maxWidth,
        width: "auto",
        height: "auto",
      }
    }
    if (_more[KMore.styleImg]) {
      _styleImg = Object.assign(_styleImg, _more[KMore.styleImg])
    }
    let _linkPhoto = link
    if (more[KMore.fPhoto]) {
      _linkPhoto = row[more[KMore.fPhoto]]
    }
    if (_linkPhoto) {
      return <img alt="" src={_linkPhoto} style={_styleImg} />
    } else {
      return <div></div>
    }
  }
  let _uiIcon = FlowRenderIconFromMore({
    extra,
    more,
    fieldName,
    isActive: valueCell != null && valueCell != "",
  })
  if (_uiIcon) {
    return _uiIcon
  }
  let _title = valueCell
  if (_more[KMore.linkTitle]) {
    _title = _more[KMore.linkTitle]
  } else if (row && _more[KMore.fLinkTitle] && row[_more[KMore.fLinkTitle]]) {
    _title = row[_more[KMore.fLinkTitle]]
  }
  return _title
}

export const FlowGetLinkTarget = ({ extra, more, fieldName }) => {
  let _target = "_blank"
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.newWindow] === true) {
    return "_blank"
  } else if (_more[KMore.newWindow] === false) {
    return "_self"
  }
  return _target
}

export const FlowGetStyleInput = ({ extra, more, fieldName, defaultStyle }) => {
  let _styleInput = defaultStyle || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.styleInput]) {
    _styleInput = { ..._styleInput, ..._more[KMore.styleInput] }
  }
  return _styleInput
}

export const FlowGetMoreAttrsInput = ({
  extra,
  more,
  fieldName,
  defaultAttrs,
}) => {
  let _attrs = defaultAttrs || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.placeHolder]) {
    _attrs = Object.assign(_attrs, {
      placeholder: _more[KMore.placeHolder],
    })
  }
  return _attrs
}

export const FlowGetLink = ({ extra, row, more, fieldName, valueCell }) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _link = valueCell
  if (extra && extra.Type && extra.Type.link) {
    _link = extra.Type.link
  }
  if (_more[KMore.fLink]) {
    if (row && row[_more[KMore.fLink]]) _link = row[_more[KMore.fLink]]
    else {
      _link = ""
    }
  } else if (_more[KMore.link]) {
    _link = _more[KMore.link]
  }
  if (_link && typeof _link == "string" && _link.indexOf("[") > -1) {
    _link = HUtils.getTextWithData(_link, row)
  }
  return _link
}

export const FlowGetHeaderTitle = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
  notUseFieldName,
  customTitle,
} = {}) => {
  let _title = defaultValue || ""
  let _extraOfColumn = FlowGetExtraOfFieldName(extra, fieldName)
  // let _more = FlowGetMore({ extra, more, fieldName })
  // console.warn("FlowGetHeaderTitle", _title)
  if (notUseFieldName !== true && _title == "") {
    _title = fieldName || extra.fieldName
  }
  if (_extraOfColumn.Header) {
    //for header json
    if (_extraOfColumn.Header.startsWith("{")) {
      let _objHeader = HJson.getObj(_extraOfColumn.Header)
      _title = _objHeader.header || ""
    } else {
      _title = _extraOfColumn.Header
    }
  }
  if (customTitle) {
    return customTitle(_title)
  }
  return _title
}

export const FlowGetTitleOfColumn = ({ extra, row, more, fieldName }) => {
  return FlowGetHeaderTitle({ extra, row, more, fieldName })
}

//Dung cho cac kieu title de hover show tooltip, button
export const FlowGetTitleTooltipOfColumn = ({
  extra,
  row,
  more,
  fieldName,
  title,
  fnList,
}) => {
  let _title = title || FlowGetTitleOfColumn({ extra, fieldName })
  let _configTitleTooltip = FlowGetConfigOfMore({
    extra,
    row,
    fieldName,
    key: KMore.configTitleTooltip,
  })
  if (_configTitleTooltip) {
    if (_configTitleTooltip.fTitle != null) {
      _title = row[_configTitleTooltip.fTitle]
    } else if (_configTitleTooltip.title != null) {
      _title = HUtils.getTextWithData(_configTitleTooltip.title, row)
    }
  }
  return FlowGetTextOrHTMLWithI18n(_title, {
    extra,
    more,
    fieldName,
    fnList,
    from: "ctt",
  })
}

export const FlowGetTableConfig = ({ extra, row, more, key, defaultValue }) => {
  let _fnList = FlowGetFnList({ extra, fieldName: extra.fieldName })
  if (_fnList) {
    let _value = null
    //v1
    if (_fnList.fnGetTableConfig) {
      _value = _fnList.fnGetTableConfig(key, defaultValue)
    } else {
      _value = fnListMyPage.fnGetConfigPageTable(_fnList, [key])
    }
  }
}
export const FlowGetConfigTrueByAllLevel = ({ key, extra, row, more } = {}) => {
  const _configInTableConfig = FlowGetTableConfig({ extra, row, more, key })
  if (more && more[key] === true) {
    // console.warn("more:",more)
    return true
  } else if (_configInTableConfig === true) {
    // console.warn("_configInTableConfig:",_configInTableConfig)
    return true
  } else if (HCF.getCF(key) === true) {
    // console.warn("AppConfig:",HConfig)
    return true
  }
  return false
}

export const FlowGetBackgroundStyleCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultStyle,
}) => {
  let _style = defaultStyle || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.style]) {
    _style = { ..._style, ..._more[KMore.style] }
  }
  if (extra != null && row != null) {
    if (extra.Type) {
      //style from bgF
      if (extra.Type.bgF != null) {
        if (row[extra.Type.bgF] != null) {
          // let _color = HColor.getTextWB(row[extra.Type.bgF]);
          _style = Object.assign(_style, { background: row[extra.Type.bgF] })
        } else if (
          extra.Type.bgF.startsWith("#") ||
          extra.Type.bgF.startsWith("linear-gradient")
        ) {
          // let _color = HColor.getTextWB(extra.Type.bgF);
          _style = Object.assign(_style, { background: extra.Type.bgF })
        }
      }
    }
  }
  // console.warn("FlowGetBackgroundStyleCell:", _style)
  return _style.background
}

export const FlowGetStyleControlOfCell = ({
  extra,
  more,
  row,
  fieldName,
  styleWrap,
  defaultStyle,
  typeControl,
}) => {
  let _style = { ...defaultStyle }
  let _more = FlowGetMore({ extra, more, fieldName })
  if (typeControl == "textarea" && _more[KMore.styleTextarea]) {
    _style = { ..._style, ..._more[KMore.styleTextarea] }
  } else if (typeControl == "input" && _more[KMore.styleInput]) {
    _style = { ..._style, ..._more[KMore.styleInput] }
  } else if (_more[KMore.styleControl]) {
    _style = { ..._style, ..._more[KMore.styleControl] }
  }
  //style Inherit
  if (_more[KMTC.styleInherit] && styleWrap) {
    if (_more[KMTC.styleInherit] === true) {
      _style = { ..._style, ...styleWrap }
    } else if (Array.isArray(_more[KMTC.styleInherit])) {
      for (let k of _more[KMTC.styleInherit]) {
        if (styleWrap[k]) {
          _style[k] = styleWrap[k]
        }
      }
    }
  }
  return _style
}
export const FlowGetStyleCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultStyle,
  overideStyle,
  oneTableCell,
}) => {
  let _styleDefaultCell = HCF.getCF(KConfigApp.styleDefaultCell)
  let _style = { ..._styleDefaultCell, ...defaultStyle }
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.style]) {
    _style = { ..._style, ..._more[KMore.style] }
  }
  if (extra != null && row != null) {
    let _styleInRow = null
    //from row.StyleRow
    if (row && row.StyleRow) {
      _styleInRow = row.StyleRow
    }
    //from row.UI_StyleRow
    if (row && row.UI_StyleRow) {
      _styleInRow = row.UI_StyleRow
    }
    if (_styleInRow) {
      if (typeof _styleInRow == "object") {
        _style = Object.assign(_style, _styleInRow)
      } else if (HIs.isJsonString(_styleInRow)) {
        _style = Object.assign(_style, HJson.getObj(_styleInRow))
      }
    }

    //from extra.Style
    if (extra.Style) {
      if (HIs.isJsonString(extra.Style)) {
        _style = { ..._style, ...HJson.getObj(extra.Style) }
      } else if (HIs.isObject(extra.Style)) {
        _style = { ..._style, ...extra.Style }
      }
    }
    if (extra.Type) {
      //style from bgF
      if (extra.Type.bgF != null) {
        if (row[extra.Type.bgF] != null) {
          // let _color = HColor.getTextWB(row[extra.Type.bgF]);
          let _s = {
            background: row[extra.Type.bgF],
          }
          // console.log("_s:", _s)
          if (_more[KMore.autoTextWB] == true) {
            _s.color = HColor.getTextWB(_s.background)
          }
          _style = Object.assign(_style, _s)
        } else if (
          extra.Type.bgF.startsWith("#") ||
          extra.Type.bgF.startsWith("linear-gradient")
        ) {
          // let _color = HColor.getTextWB(extra.Type.bgF);
          _style = Object.assign(_style, { background: extra.Type.bgF })
        }
      }
      //style from colorF
      if (extra.Type.colorF != null) {
        if (row[extra.Type.colorF] != null) {
          _style = Object.assign(_style, { color: row[extra.Type.colorF] })
        } else if (extra.Type.colorF.startsWith("#")) {
          _style = Object.assign(_style, { color: extra.Type.colorF })
        }
      }
    }
  }
  //bo sung styleDisabled
  if (_more[KMore.styleDisabled]) {
    _style = Object.assign(_style, _more[KMore.styleDisabled])
  }

  //styleByValue
  // console.warn("styleByValue 1",extra.Type,_more,_more[KMore.styleByValue])
  if (_more[KMore.styleByValue]) {
    // console.warn("styleByValue",_more[KMore.styleByValue])
    let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
    let _valueCell = row[_fieldName]
    if (HFormat.Types.configByValue) {
      let _moreStyle = HFormat.format(_valueCell, {
        type: "configByValue",
        configByValue: _more[KMore.styleByValue],
      })
      if (_moreStyle) {
        _style = Object.assign(_style, _moreStyle)
      }
    }
  }
  //bo sung cho dep
  if (
    _more[KMore.fBorderLeftColor] &&
    row[_more[KMore.fBorderLeftColor]] &&
    _style
  ) {
    _style.borderLeft = `4px solid ${row[_more[KMore.fBorderLeftColor]]}`
  }

  //bo sung overideStyle
  if (overideStyle) {
    _style = { ..._style, ...overideStyle }
  }
  return _style
}

export const FlowGetClassCell = ({
  extra,
  more,
  row,
  fieldName,
  defaultClass,
}) => {
  let _className = defaultClass || ""
  let _more = FlowGetMore({ extra, more, fieldName })
  //class from extra
  if (extra.ClassName) {
    _className += ` ${extra.ClassName}`
  }
  //class from more
  if (_more[KMore.className]) {
    _className += ` ${_more[KMore.className]}`
  }
  //class fieldName
  if (extra.fieldName) {
    if (
      FlowGetConfigTrueByAllLevel({
        key: KConfigApp.showClassFieldOnCell,
        extra,
        row,
        more: _more,
      })
    ) {
      _className += ` col-${extra.fieldName}`
    } else if (_more.classNameWithColField != null) {
      if (_more.classNameWithColField === true) {
        _className += ` col-${extra.fieldName}`
      } else {
        _className += ` ${_more.classNameWithColField}-${extra.fieldName}`
      }
    }
  }
  return _className
}

export const FlowGetCanEdit = ({ extra, row, more, fieldName } = {}) => {
  let _canEdit = true
  let _more = FlowGetMore({ extra, more, fieldName })
  //check row locked
  if (row && row.IsLocked === true) {
    return false
  }
  //check extraColumn.CanEdit
  let _extraCol = extra
  if (_extraCol) {
    if (fieldName && extra.fieldName != fieldName) {
      _extraCol = FlowTableExtraData.FlowGetColumnFromExtraData(
        FlowGetExtraDataFromExtra(extra),
        fieldName
      )
      if (_extraCol && _extraCol.CanEdit != null) {
        _canEdit = _extraCol.CanEdit
      }
    } else if (_extraCol.CanEdit != null) {
      _canEdit = _extraCol.CanEdit
    }
  }
  //check more.fCanEdit more.canEditF
  if (_canEdit == true) {
    if (_more.fCanEdit == null && _more.canEditF != null) {
      _more.fCanEdit = _more.canEditF
    }
    if (typeof _more.fCanEdit === "boolean") {
      _canEdit = _more.fCanEdit
    } else if (typeof _more.fCanEdit === "string") {
      if (_more.fCanEdit.length > 0) {
        FlowUpdateRelativeByField({
          extra,
          fieldName,
          fEdit: _more.fCanEdit,
          row,
          checkChange: true,
        })
        if (row[_more.fCanEdit] != null) {
          _canEdit = row[_more.fCanEdit]
        } else if (_more.fCanEdit.indexOf(",") > -1) {
          let _arrcanEditF = _more.fCanEdit.split(",")
          if (_arrcanEditF && _arrcanEditF.length > 0) {
            for (let f of _arrcanEditF) {
              if (row[f] == false) {
                //only need one cannot edit -> cannot edit
                _canEdit = false
                break
              }
            }
          }
        }
      }
    }
  }
  //check more.lockF more.fLock
  if (_more.lockF != null || _more.fLock != null) {
    let _fLock = _more.lockF || _more.fLock
    if (typeof _fLock == "boolean") {
      _canEdit = !_fLock
    } else if (typeof _fLock == "string") {
      if (_fLock.length > 0) {
        FlowUpdateRelativeByField({
          extra,
          fieldName,
          fEdit: _fLock,
          row,
          checkChange: true,
        })
        if (row[_fLock] != null) {
          _canEdit = !row[_fLock]
        } else if (_fLock.indexOf(",") > -1) {
          let _arrLock = _fLock.split(",")
          if (_arrLock && _arrLock.length > 0) {
            for (let f of _arrLock) {
              if (row[f] == true) {
                //only need one lock edit -> cannot edit
                _canEdit = false
                break
              }
            }
          }
        }
      }
    }
  }
  return _canEdit
}

export const FlowUpdateRelativeByField = ({
  extra,
  fieldName,
  fEdit,
  row,
  checkChange,
} = {}) => {
  if (extra && extra.extraData) {
    if (extra.extraData[kClientKeyUI._RelativeByField] == null) {
      extra.extraData[kClientKeyUI._RelativeByField] = {}
    }
    if (extra.extraData[kClientKeyUI._RelativeByField]) {
      if (extra.extraData[kClientKeyUI._RelativeByField][fEdit] == null) {
        extra.extraData[kClientKeyUI._RelativeByField][fEdit] = []
      }
      if (
        extra.extraData[kClientKeyUI._RelativeByField][fEdit] &&
        extra.extraData[kClientKeyUI._RelativeByField][fEdit].indexOf(
          fieldName
        ) == -1
      ) {
        extra.extraData[kClientKeyUI._RelativeByField][fEdit].push(fieldName)
      }
    }
  }
  if (row && checkChange == true) {
    let _fielsEffect =
      HUtils.get(row, `_UI_CheckChange.${fEdit}.fielsEffect`) || []
    if (_fielsEffect.indexOf(fieldName) == -1) {
      _fielsEffect.push(fieldName)
    }
    row[`_UI_CheckChange`] = {
      ...row[`_UI_CheckChange`],
      [fEdit]: {
        value: row[fEdit],
        fielsEffect: _fielsEffect,
      },
    }
  }
  // console.warn("FlowUpdateRelativeByField:", extra, row)
}

export const FlowGetConfigOfMore = ({
  extra,
  more,
  row,
  fieldName,
  key,
  defaultValue,
  fKey,
} = {}) => {
  let _value = defaultValue
  let _more = FlowGetMore({ extra, more, fieldName })
  console.warn("_configTitleTooltip more", _more);
  if (fKey && _more.hasOwnProperty(fKey) && row[_more.hasOwnProperty(fKey)]) {
    _value = row[_more.hasOwnProperty(fKey)]
  } else if (_more.hasOwnProperty(key)) {
    _value = _more[key]
  }
  return _value
}

export const FlowGetConfigModal = ({
  extra,
  more,
  row,
  fieldName,
  defaultValue,
} = {}) => {
  let _configModal = FlowGetConfigOfMore({
    extra,
    more,
    row,
    fieldName,
    key: KMore.modal,
    defaultValue: defaultValue || {},
  })
  if (
    _configModal[KConfigModal.fTitle] &&
    row[_configModal[KConfigModal.fTitle]]
  ) {
    _configModal.title = row[_configModal[KConfigModal.fTitle]]
    delete _configModal[KConfigModal.fTitle] //xoa fTitle trong _configModal
  }
  if (more && more[KMTC.usingI18n] && _configModal.title) {
    _configModal.title = FlowGetTextOrHTMLWithI18n(_configModal.title, {
      extra,
      more,
      fieldName,
      fnList: null,
      from: "modal",
    })
  }
  return _configModal
}

export const FlowClickToSelectInput = ({ extra, fieldName, more, ev } = {}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  if (ev && ev.target && _more && _more[KMore.clickToSelect] === true) {
    ev.target.select()
  }
}

export const FlowGetDateFormat = ({
  extra,
  fieldName,
  more,
  keyConfig,
  defaultFormat,
} = {}) => {
  // console.warn("FlowGetDateFormat", fieldName)
  let _more = FlowGetMore({ extra, more, fieldName })
  let _formatFromKeyConfig = null
  if (keyConfig) {
    _formatFromKeyConfig = HCF.getCF(keyConfig)
  }
  let _format =
    _more[KMore.dateFormat] ||
    _more[KMore.format] ||
    _formatFromKeyConfig ||
    defaultFormat ||
    HCF.getCF(KConfigApp.dateFormat)
  return _format
}

export const FlowGetDateTimeFormat = ({
  extra,
  fieldName,
  more,
  keyConfig,
  defaultFormat,
} = {}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  let _formatFromKeyConfig = null
  if (keyConfig) {
    _formatFromKeyConfig = HCF.getCF(keyConfig)
  }
  let _format =
    _more[KMore.dateFormat] ||
    _more[KMore.format] ||
    _formatFromKeyConfig ||
    defaultFormat ||
    HCF.getCF(KConfigApp.datetimeFormat)
  return _format
}

export const FlowGetColor = ({
  extra,
  more,
  row,
  fieldName,
  defaultColor,
} = {}) => {
  let _color = defaultColor || "gray"
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMore.fColor] && row && row[_more[KMore.fColor]]) {
    _color = row[_more[KMore.fColor]]
  } else if (_more[KMore.color]) {
    _color = _more[KMore.color]
  }
  return _color
}

export const FlowGetFnList = ({ extra, fieldName } = {}) => {
  let _extraCol = FlowGetExtraOfFieldName(extra, fieldName)
  if (_extraCol.fnList) {
    return _extraCol.fnList
  } else if (extra.fnList) {
    return extra.fnList
  }
  return {}
}

export const FlowGetIsUpdatingCell = ({ extra, row, fieldName } = {}) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  if (
    row &&
    row[KConfigInRow._UI_Loading] &&
    row[KConfigInRow._UI_Loading][_fieldName] === true
  ) {
    return true
  }
  return false
}

export const FlowAfterUpdateCell = ({
  row,
  response,
  fieldName,
  fnList,
  forceNeedReload,
}) => {
  ////show msg da show o trong api nen ko can show nua
  //// fnListUI.fnShowToast(response.Msg)

  //update new Data to row
  if (response && response.Data && response.Data.Id == row.Id) {
    row[KConfigInRow._UI_NeedUpdateUI] = true //de table cell render lai
    // //Test
    // if (window._testOne !== true) {
    //   response.Data.ActiveFrom_UTC = null
    //   window._testOne = true
    // }

    HUtils.mergeObjIfExist(row, response.Data)
  }
  //remove row UI loading, error
  if (row[KConfigInRow._UI_Loading] != null) {
    delete row[KConfigInRow._UI_Loading][fieldName]
  }
  if (row[KConfigInRow._UI_Error] != null) {
    delete row[KConfigInRow._UI_Error][fieldName]
  }

  let _arrFieldNeedReload = []
  let _fieldNeedReload = fnListMyPage.fnGetConfigPageTable(fnList, [
    KConfigPageTable.needReloadAfterUpdateFields,
  ])
  if (typeof _fieldNeedReload == "string") {
    _arrFieldNeedReload.push(_fieldNeedReload)
  } else if (Array.isArray(_fieldNeedReload)) {
    _arrFieldNeedReload = [..._fieldNeedReload]
  }
  if (forceNeedReload === true || _arrFieldNeedReload.indexOf(fieldName) > -1) {
    console.warn("RELOAD after update field", fnList)
    fnListMyPage.fnReloadList(fnList, [])
  } else if (forceNeedReload == "optionslist") {
    fnListMyPage.fnReloadOptionsAndList(fnList, [])
  }
}

export const FlowUpdateCell = ({
  extra,
  more,
  row,
  fieldName,
  newValue,
  alwaysUpdate,
  fnForceUpdateCell,
  fnSuccessCallBack,
  forceNeedReload,
} = {}) => {
  let _fieldName = fieldName || FlowGetFieldNameFromExtra(extra)
  let _valueCell = row[_fieldName]
  let _fnList = FlowGetFnList({ extra, fieldName })
  // let _extraCol = FlowGetExtraOfFieldName(extra,fieldName)
  //lay fnRequestUpdate
  // let _fnRequestUpdate = _fnList.fnRequestUpdate;
  // if(_extraCol.fnRequestUpdate){
  //   _fnRequestUpdate = _extraCol.fnRequestUpdate;
  // }
  // console.warn("FlowUpdateCell:",_valueCell,"[new]:",newValue,_fnRequestUpdate)

  //init UILoading,Error o row
  if (row[KConfigInRow._UI_Loading] == null) {
    row[KConfigInRow._UI_Loading] = {}
  }
  if (row[KConfigInRow._UI_Error] == null) {
    row[KConfigInRow._UI_Error] = {}
  }

  let _canUpdate = false
  //kiem tra co update dc ko
  if (newValue != _valueCell && !(_valueCell == null && newValue == "")) {
    _canUpdate = true
  } else if (newValue == _valueCell) {
    if (
      row[KConfigInRow._UI_Error] &&
      row[KConfigInRow._UI_Error][_fieldName] != null
    ) {
      _canUpdate = true
    } else if (alwaysUpdate == true) {
      _canUpdate = true
    }
    console.warn("New Update = current value cell:", newValue, _valueCell)
  }
  //tien hanh update
  if (_canUpdate) {
    console.warn("Need Update:", newValue, _fnList)
    //gan gia tri updating vao row
    if (row[KConfigInRow._UI_Loading] != null) {
      row[KConfigInRow._UI_Loading][_fieldName] = true
    }

    //loading update
    if (fnForceUpdateCell) {
      fnForceUpdateCell()
    }

    //Bo sung goi request update neu truyen tu ben ngoai vao, mot so man hinh cu van su dung, de update detail
    if (extra.fnRequestUpdate) {
      extra.fnRequestUpdate(row, _fieldName, newValue, {
        fnUpdateUILoading: (isLoading, opts) => {
          if (row[KConfigInRow._UI_Loading] == null) {
            row[KConfigInRow._UI_Loading] = {}
          }
          if (row[KConfigInRow._UI_Loading] != null) {
            row[KConfigInRow._UI_Loading][_fieldName] = isLoading
          }
          if (opts && opts.component && opts.component.forceUpdate) {
            opts.component.forceUpdate()
          }
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
        fnUpdateUIError: (msg, opts) => {
          if (row[KConfigInRow._UI_Error] == null) {
            row[KConfigInRow._UI_Error] = {}
          }
          if (msg == null) {
            //msg == null nghia la xoa error di
            delete row[KConfigInRow._UI_Error][_fieldName]
          } else {
            row[KConfigInRow._UI_Error][_fieldName] = {
              msg: msg,
            }
          }
          // console.warn("fnUpdateUIError",msg,fieldName,row,opts,component);
          if (opts && opts.component && opts.component.forceUpdate) {
            opts.component.forceUpdate()
          }
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
        fnSuccessCallBack: (response, opts) => {
          if (fnSuccessCallBack) {
            fnSuccessCallBack(response, opts)
          }
        },
      })
    } else {
      //goi ham request update
      FlowApi.FlowApiUpdateFields({
        row,
        apiController: fnListMyPage.fnGetApiPath(_fnList) || "NoApiPath",
        sharedRequestData: fnListMyPage.fnGetSharedRequestData(_fnList),
        multiObjUpdate: {
          [_fieldName]: newValue,
        },
        cbSuccess: response => {
          console.warn("success:", response)
          FlowAfterUpdateCell({
            row,
            response,
            fieldName: _fieldName,
            fnList: _fnList,
            forceNeedReload,
          })

          //force update cell
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
          //forece update table de update row khac neu co su thay doi
          fnListMyPage.fnForceUpdateTable(_fnList)
        },
        cbError: (error, response) => {
          console.warn("error:", error, response)
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
          //remove row UI loading, update error
          if (row[KConfigInRow._UI_Loading] != null) {
            delete row[KConfigInRow._UI_Loading][_fieldName]
          }
          if (row[KConfigInRow._UI_Error] != null) {
            row[KConfigInRow._UI_Error][_fieldName] = error || response.Msg
          }
          //force update cell
          if (fnForceUpdateCell) {
            fnForceUpdateCell()
          }
        },
      })
    }
  }
}

export const FlowGetQueryFromConfigQuery = ({ row, configQuery } = {}) => {
  let _newQuery = {}
  if (configQuery && row) {
    let _keys = Object.keys(configQuery)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        if (
          HIs.isString(configQuery[k]) &&
          row.hasOwnProperty(configQuery[k])
        ) {
          _newQuery[k] = row[configQuery[k]]
        } else {
          _newQuery[k] = configQuery[k]
        }
      }
    }
  }
  return _newQuery
}

export const FlowRenderLoading = ({ extra, row, fieldName, uiLoading }) => {
  if (FlowGetIsUpdatingCell({ extra, row, fieldName }) === true) {
    if (uiLoading) {
      return uiLoading
    }
    return <MyUI type={KTypeCellShared._cell_loading} />
  }
}

export const FlowRenderOverlay = ({ extra, row, fieldName, uiLoading }) => {
  //render loading, error, addable, infomation, clear
}

export const FlowCheckUpdateValueAfterWillReceiveProps = (
  props,
  nextProps,
  valueDefault = ""
) => {
  const { cell, row, extra } = props
  const newCell = nextProps.cell
  if (row && extra && row._UI_NeedUpdate) {
    let _fieldName = extra.fieldName
    if (_fieldName && row._UI_NeedUpdate.hasOwnProperty(_fieldName)) {
      let _value = row._UI_NeedUpdate[_fieldName]
      delete row._UI_NeedUpdate[_fieldName]
      return {
        needUpdate: true,
        value: _value,
      }
    }
  }
  if (newCell != null && cell != null && newCell != cell) {
    return {
      needUpdate: true,
      value: newCell,
    }
  } else if (cell == null && newCell != cell) {
    return {
      needUpdate: true,
      value: newCell,
    }
  } else if (newCell == null && newCell != cell) {
    return {
      needUpdate: true,
      value: valueDefault,
    }
  }
  return {
    needUpdate: false,
    newCell: newCell,
  }
}

export const FlowAddNeedUpdateUIByFields = ({
  extra,
  row,
  fieldNeedUpdate,
}) => {
  if (row) {
    let _UI_NeedUpdateUIByFields = row["_UI_NeedUpdateUIByFields"] || []
    if (_UI_NeedUpdateUIByFields.indexOf(fieldNeedUpdate) == -1) {
      _UI_NeedUpdateUIByFields.push(fieldNeedUpdate)
    }
    row["_UI_NeedUpdateUIByFields"] = _UI_NeedUpdateUIByFields
  }
}
export const FlowUpdateOtherFieldRelative = ({
  extra,
  row,
  newRow,
  fieldName,
  more,
  relativeOfField,
}) => {
  // console.error("FlowUpdateOtherFieldRelative:", extra, relativeOfField)
  if (row) {
    let _more = FlowGetMore({ extra, more, fieldName }) || {}
    let _UI_NeedUpdateUIByFields = row["_UI_NeedUpdateUIByFields"] || []
    if (Array.isArray(_UI_NeedUpdateUIByFields)) {
      if (
        _more[KMore.updateUIRelative] &&
        _more[KMore.updateUIRelative].split
      ) {
        let _arr = _more[KMore.updateUIRelative].split(",")
        for (let k of _arr) {
          _UI_NeedUpdateUIByFields.push(k)
        }
      }
      //by check fCanEdit
      if (relativeOfField && Array.isArray(relativeOfField)) {
        for (let k of relativeOfField) {
          _UI_NeedUpdateUIByFields.push(k)
        }
      }
      //test
      // _UI_NeedUpdateUIByFields.push("Value")
    }
    if (row["_UI_CheckChange"] && newRow != null) {
      console.warn("_UI_CheckChange:", row)
      for (let k of Object.keys(row["_UI_CheckChange"])) {
        let _f = k
        if (row["_UI_CheckChange"][k].value != newRow[k]) {
          row["_UI_CheckChange"][k].value = newRow[k]
          if (
            Array.isArray(row["_UI_CheckChange"][k].fielsEffect) &&
            row["_UI_CheckChange"][k].fielsEffect.length > 0
          ) {
            for (let f of row["_UI_CheckChange"][k].fielsEffect) {
              if (_UI_NeedUpdateUIByFields.indexOf(f) == -1) {
                _UI_NeedUpdateUIByFields.push(f)
              }
            }
          }
        }
      }
    }
    row["_UI_NeedUpdateUIByFields"] = _UI_NeedUpdateUIByFields
    // console.warn("_UI_NeedUpdateUIByFields:")
  }
}

export const FlowGetCustomDefaultValueFromRow = ({
  row,
  custom, //{"fForm":"fRow"}
}) => {
  let _defaultValues = {}
  if (row && custom) {
    for (let k of Object.keys(custom)) {
      _defaultValues[k] = row[custom[k]]
    }
  }
  return _defaultValues
}

export const FlowGetDefaultValueFromRow = ({ row, more, dataFromApi }) => {
  //uu tien data tu checkapishowmodal
  if (dataFromApi != null) {
    return dataFromApi
  }
  let _defaultValuesForm = row
  if (more[KMore.customDefaultValues]) {
    //dung cho viec muon co defaultValue nhung chi vai field cua row
    _defaultValuesForm = FlowGetCustomDefaultValueFromRow({
      row,
      custom: more[KMore.customDefaultValues],
    })
  } else if (more[KMore.fDefaultValues]) {
    if (more[KMore.fDefaultValues] == "row") {
      _defaultValuesForm = { ...row }
    } else if (row[more[KMore.fDefaultValues]]) {
      _defaultValuesForm = row[more[KMore.fDefaultValues]]
    } else {
      _defaultValuesForm = {}
    }
  } else if (more[KMore.fDefaultValues] === null) {
    _defaultValuesForm = {}
  }
  //dung de loai tru mot so field
  if (more[KMore.excludeDefaultValues]) {
    let _newDefaulValues = {
      ..._defaultValuesForm,
    }
    for (let k of more[KMore.excludeDefaultValues]) {
      delete _newDefaulValues[k]
    }
    _defaultValuesForm = _newDefaulValues
  }
  return _defaultValuesForm
}

export const FlowGetNeedReloadForCell = ({ extra, more, row, fieldName }) => {
  let _forceNeedReload = false
  let _more = FlowGetMore({ extra, more, fieldName })
  _forceNeedReload = FlowGetBoolOfMoreFromRow({
    row,
    more: _more,
    key: KMoreOfTableCell.needReloadAfterUpdateFields,
  })
  console.warn("_forceNeedReload:", _forceNeedReload)
  return _forceNeedReload
}

export const FlowUpdateQuery = ({
  extra,
  more,
  row,
  oneTableCell,
  fieldName,
  fnList,
  currentQuery,
}) => {
  let _query = currentQuery || {}
  let _more = FlowGetMore({ extra, more, fieldName })
  if (_more[KMTC.queryWithQuerySharedPage] === true) {
    let _qureySharedMyPage = fnListMyPage.fnGetSharedRequestData(fnList)
    console.warn("_queryShared:", _qureySharedMyPage)
    _query = Object.assign(_qureySharedMyPage, _query)
  }

  return _query
}

export const FlowShowLoadingFullRow = ({
  row,
  more,
  extra,
  isShow,
  fieldName,
}) => {
  if (
    FlowGetBoolOfMoreFromRow({
      key: KMore.needLoadingFullRow,
      row,
      more,
    }) !== true
  ) {
    return
  }
  if (row && extra && extra.fnForceUpdateTableBody) {
    if (isShow) {
      row.UI_ClassTableRow = "ag-row-isloading"
      if (row.UI_ClassTableCell == null) {
        row.UI_ClassTableCell = {}
      }
      if (row.UI_ClassTableCell) {
        row.UI_ClassTableCell[fieldName] = "ag-cell-isloading"
      }
      if (row.UI_ComponentAppendCell == null) {
        row.UI_ComponentAppendCell = {}
      }
      if (row.UI_ComponentAppendCell) {
        // row.UI_ComponentAppendCell[fieldName] = "Hello"
        row.UI_ComponentAppendCell[fieldName] = () => {
          return (
            <div className="ag-row-overlay ani-loading-bg2">
              {/* <i class="fa fa-spin fa-spinner" /> */}
            </div>
          )
        }
      }
      if (extra && extra.fnForceUpdateTableBody) {
        extra.fnForceUpdateTableBody()
      }
    } else {
      row.UI_ClassTableRow = ""
      if (row.UI_ClassTableCell == null) {
        row.UI_ClassTableCell = {}
      }
      if (row.UI_ClassTableCell) {
        row.UI_ClassTableCell[fieldName] = ""
      }
      if (row.UI_ComponentAppendCell == null) {
        row.UI_ComponentAppendCell = {}
      }
      if (row.UI_ComponentAppendCell) {
        // row.UI_ComponentAppendCell[fieldName] = "Hello"
        row.UI_ComponentAppendCell[fieldName] = null
      }
      if (extra && extra.fnForceUpdateTableBody) {
        extra.fnForceUpdateTableBody()
      }
    }
  }
}

export const FlowUpdateWaitingApi = ({
  extra,
  row,
  newRow,
  fieldName,
  more,
  oneTableCell,
  loading,
}) => {
  let _more = FlowGetMore({ extra, more, fieldName })
  console.log("FlowUpdateWaitingApi:", _more)
  if (_more[KMore.customRowBeforeRequest]) {
    let _fields = Object.keys(_more[KMore.customRowBeforeRequest])
    for (let k of _fields) {
      row[k] = _more[KMore.customRowBeforeRequest][k]
    }
  }
  if (_more[KMore.waitApi] && Array.isArray(_more[KMore.waitApi])) {
    for (let k of _more[KMore.waitApi]) {
      FlowAddNeedUpdateUIByFields({ extra, row, fieldNeedUpdate: k })
      FlowSetConfigCellInRow({
        oneTableCell,
        row,
        fieldName: k,
        key: `waiting`,
        value: loading,
      })
    }
  }
}

export const FlowSetConfigCellInRow = ({
  oneTableCell,
  row,
  fieldName,
  key,
  value,
}) => {
  if (oneTableCell && oneTableCell._onePage) {
    if (oneTableCell._onePage[KOnePageMore._configRowById] == undefined) {
      oneTableCell._onePage[KOnePageMore._configRowById] = {}
    }
    console.log("FlowSetConfigCellInRow:", key, value, oneTableCell)
    let _obj = oneTableCell._onePage[KOnePageMore._configRowById]
    if (row.Id && key) {
      if (_obj[row.Id] == undefined) {
        _obj[row.Id] = {}
      }
      if (_obj[row.Id][fieldName] == undefined) {
        _obj[row.Id][fieldName] = {}
      }
      _obj[row.Id][fieldName][key] = value
    }
  }
}

export const FlowGetOnePageFromOneTableCell = ({ oneTableCell, onePage }) => {
  if (oneTableCell && oneTableCell._onePage) {
    return oneTableCell._onePage
  }
  return onePage || null
}

export const FlowGetConfigRows = ({ oneTableCell, onePage }) => {
  let _onePage = FlowGetOnePageFromOneTableCell({ oneTableCell, onePage })
  if (_onePage) {
    if (_onePage[KOnePageMore._configRowById] == undefined) {
      _onePage[KOnePageMore._configRowById] = {
        a: 1,
      }
    }
    return _onePage[KOnePageMore._configRowById]
  }
}

export const FlowGetConfigRowById = ({ oneTableCell, onePage, row }) => {
  let _configRows = FlowGetConfigRows({ oneTableCell, onePage })
  if (_configRows && row && row.Id) {
    if (_configRows[row.Id] == undefined) {
      _configRows[row.Id] = {}
    }
    return _configRows[row.Id]
  }
}

export const FlowSetConfigInRow = ({
  oneTableCell,
  onePage,
  row,
  key,
  value,
}) => {
  let _configInRow = FlowGetConfigRowById({ oneTableCell, onePage, row })
  if (_configInRow && key) {
    _configInRow[key] = value
  }
}

export const FlowGetConfigInRow = ({ oneTableCell, onePage, row, key }) => {
  let _configInRow = FlowGetConfigRowById({ oneTableCell, onePage, row })
  if (_configInRow && key) {
    return _configInRow[key]
  }
}

export const FlowGetConfigCellInRow = ({
  oneTableCell,
  row,
  fieldName,
  key,
  df,
}) => {
  return (
    HUtils.get(
      oneTableCell,
      `_onePage.${KOnePageMore._configRowById}.${row.Id}.${fieldName}.${key}`
    ) || df
  )
}

export const FlowGetConfigWaitingCellInRow = ({
  oneTableCell,
  row,
  fieldName,
  df,
}) => {
  return FlowGetConfigCellInRow({
    oneTableCell,
    row,
    fieldName,
    key: "waiting",
    df,
  })
}

export const FlowForceUpdateTable = ({ row, oneTableCell }) => {
  if (
    oneTableCell &&
    oneTableCell._onePage &&
    oneTableCell._onePage[KOnePageMore._forceUpdateTable]
  ) {
    oneTableCell._onePage[KOnePageMore._forceUpdateTable]({ row, oneTableCell })
  }
}
export const FlowForceUpdateTableRow = ({ oneTableCell, onePage, row }) => {
  let _configInRow = FlowGetConfigRowById({ oneTableCell, onePage, row })
  if (_configInRow && _configInRow["fnForceUpdateTableRow"]) {
    _configInRow["fnForceUpdateTableRow"]()
  }
}
//justnew
export const FlowAddConfigJustNewInRow = ({
  oneTableCell,
  onePage,
  row,
  needForceUpdate,
}) => {
  FlowSetConfigInRow({
    oneTableCell,
    onePage,
    row,
    key: "justNew",
    value: true,
  })
  if (needForceUpdate) {
    FlowForceUpdateTableRow({ oneTableCell, onePage, row })
  }
}
export const FlowGetConfigJustNewInRow = ({ oneTableCell, onePage, row }) => {
  return FlowGetConfigInRow({ oneTableCell, onePage, row, key: "justNew" })
}
//justdelete
export const FlowAddConfigJustDeleteInRow = ({
  oneTableCell,
  onePage,
  row,
  needForceUpdate,
}) => {
  FlowSetConfigInRow({
    oneTableCell,
    onePage,
    row,
    key: "justDelete",
    value: true,
  })
  if (needForceUpdate) {
    FlowForceUpdateTableRow({ oneTableCell, onePage, row })
  }
}
export const FlowGetConfigJustDeleteInRow = ({
  oneTableCell,
  onePage,
  row,
}) => {
  return FlowGetConfigInRow({ oneTableCell, onePage, row, key: "justDelete" })
}
//loading
export const FlowAddConfigLoadingInRow = ({
  oneTableCell,
  onePage,
  row,
  value,
  needForceUpdate,
}) => {
  FlowSetConfigInRow({
    oneTableCell,
    onePage,
    row,
    key: "loading",
    value: value === false ? false : true,
  })
  if (needForceUpdate) {
    FlowForceUpdateTableRow({ oneTableCell, onePage, row })
  }
}
export const FlowGetConfigLoadingInRow = ({ oneTableCell, onePage, row }) => {
  return FlowGetConfigInRow({ oneTableCell, onePage, row, key: "loading" })
}
