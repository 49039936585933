import React from "react"
import { MyCardHelpers } from "@macashipo/mlib"

const MEditableFuneralServiceDetailCard = React.lazy(
  () => import("./Types/EditableFuneralServiceDetailCard")
)
const MReadonlyFuneralServiceDetailCard = React.lazy(
  () => import("./Types/ReadonlyFuneralServiceDetailCard")
)
const MHistoryActionCard = React.lazy(() => import("./Types/HistoryActionCard"))
const MStatusOptionCard = React.lazy(() => import("./Types/StatusOptionCard"))
const MArrangementDocumentCard = React.lazy(
  () => import("./Types/ArrangementDocumentCard")
)
const MArrangementTaskCard = React.lazy(
  () => import("./Types/ArrangementTaskCard")
)
const MArrangementFileCard = React.lazy(
  () => import("./Types/ArrangementFileCard")
)
const List = {
  editable_funeral_service_detail_card: MEditableFuneralServiceDetailCard,
  readonly_funeral_service_detail_card: MReadonlyFuneralServiceDetailCard,
  history_action_card: MHistoryActionCard,
  status_option_card: MStatusOptionCard,
  arrangement_document_card: MArrangementDocumentCard,
  arrangement_task_card: MArrangementTaskCard,
  arrangement_file_card: MArrangementFileCard,
}
const MyLibOne = {
  init() {
    MyCardHelpers.addTypesFromListLazy(List)
  },
}

export default MyLibOne
