import { fnListUI } from "src/helpers/fnList"
import * as HRegex from "src/core/helpers/regex"
export const RoutesPublic = []
export const RoutesPrivate = []
export const RoutesTest = []

/**
 * Route Config:
 * {UIUrl:'/demo',ScreenCode:'',Title:'',APIName:'API',UIType:'Client', Config: '{"type":"solid_wpgstandard_searchv3","test":true}'},
 */

//gan forceupdate vao HCF, goi force update tu HCF
export const FlowForceUpdateRoutes = () => {
  fnListUI.fnForceUpdateRoutes()
}

//from categoryScreenList
export const FlowParseCategoryScreenList = () => {}

//from first setting
export const FlowParsePublicApiScreenList = () => {}

const AddRoutes = (list = [], arrConfigRoute) => {
  if (arrConfigRoute && arrConfigRoute.length > 0) {
    for (let i = 0; i < arrConfigRoute.length; i++) {
      list.push(arrConfigRoute[i])
    }
  }
  FlowForceUpdateRoutes()
}
export const FlowAddPublicRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesPublic, arrConfigRoute)
}

export const FlowAddPrivateRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesPrivate, arrConfigRoute)
}

export const FlowAddTestRoute = (arrConfigRoute = []) => {
  AddRoutes(RoutesTest, arrConfigRoute)
}

export const FlowParseDataRoutes = (data, { forceChange } = {}) => {
  let _newData = []
  if (data && data.length > 0) {
    _newData = data.map((v, i) => {
      if (v.UIUrl && v.UIUrl.indexOf("[") > -1) {
        v.UIUrl = v.UIUrl.replace(HRegex.RGSquareBracket, ":$1")
      }
      FlowConvertUIType(v)
      return v
    })
  }
  return _newData
}

const ConvertType = {
  table: "admin_table",
  tab: "admin_tab",
  dashboard: "admin_dashboard",
  panel: "admin_panel",
  table_list: "admin_table_list",
  table_list_card: "admin_table_list_card",
  table_grid_card: "admin_table_grid_card",
}

export const FlowConvertUIType = (configPage = {}) => {
  if (configPage.UIType === null || configPage.UIType === "") {
    configPage.UIType = "Table"
  }
  if (configPage.UIType && ConvertType[configPage.UIType.toLowerCase()]) {
    configPage.UIType = ConvertType[configPage.UIType.toLowerCase()]
  }
  // console.warn("convertUIType", configPage)
}

window._Routes = {
  RoutesPublic,
  RoutesPrivate,
  RoutesTest,
}
